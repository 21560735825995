import { functions } from '@/core/firebase';
import { useFormList } from '@/core/hooks/useFormList';
import Container from '@/features/common/components/Container';
import LoadingSkeleton from '@/features/common/components/LoadingSkeleron';
import { FormModel } from '@/features/form/data/model/FormModel';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  MDBBadge,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBSpinner,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import EditFormModal from '../components/EditFormModal';
import SendFormModal from '../components/SendFormModal';
import ViewFormModal from '../components/ViewFormModal';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

/**
 * FormsScreen component represents the list of forms in the application.
 * @returns The FormsScreen component.
 */
const FormsScreen: React.FC = () => {
  // Form Lisdt hook for state management
  const { formList, setFormList } = useFormList();

  // State variables
  const [fetching, setFetching] = useState<boolean>(true);
  const [creatingForm, setCreatingForm] = useState<boolean>(false);
  const [sendModal, setSendingModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [formId, setFormId] = useState<string | undefined>(undefined);
  const [resendingForm, setResendingForm] = useState<boolean>(false);
  const [finalizingForm, setFinalizingForm] = useState<boolean>(false);
  const [downloadingPdf, setDownloadingPdf] = useState<boolean>(false);
  const [downloadingWord, setDownloadingWord] = useState<boolean>(false);

  /**
   * Toggles the create form modal.
   */
  const toggleSendModal = async () => {
    if (!sendModal && !formId) {
      setCreatingForm(true);

      // Firebase function for updating user information
      const createForm = httpsCallable(functions, 'createForm');

      try {
        // Call the function to create the form
        const form = (await createForm()).data as FormModel;

        // Set the form id to the new form
        setFormId(form.id);

        // Update the form list with the new form
        setFormList([...formList, form]);
      } catch (error) {
        console.error(error);
        return;
      } finally {
        // Set the creating form state to false
        setCreatingForm(false);
      }
    }

    return setSendingModal(!sendModal);
  };

  /**
   * Toggles the edit form modal.
   */
  const toggleEditModal = () => {
    if (editModal) {
      setFormId(undefined);
    }
    return setEditModal(!editModal);
  };

  /**
   * Toggles the view form modal.
   */
  const toggleViewModal = () => {
    if (viewModal) {
      setFormId(undefined);
    }
    return setViewModal(!viewModal);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'created':
        return 'warning';
      case 'sent':
        return 'primary';
      case 'in-progress':
        return 'info';
      case 'complete':
        return 'success';
      default:
        return 'light';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'created':
        return 'Creado';
      case 'sent':
        return 'Enviado';
      case 'in-progress':
        return 'En progreso';
      case 'complete':
        return 'Completado';
      default:
        return 'Creado';
    }
  };

  // Firebase function for resending the form
  const resendForm = httpsCallable(functions, 'resendForm');

  const handleResendForm = (formId: string) => {
    setResendingForm(true);
    resendForm({ id: formId })
      .then(() => {
        setFormList(formList.map((form) => (form.id === formId ? { ...form, status: 'sent' } : form)));
      })
      .catch(console.error)
      .finally(() => setResendingForm(false));
  };

  // Firebase function for updating user information
  const finalizeForm = httpsCallable(functions, 'finalizeForm');

  const handleFinalizeForm = (formId: string) => {
    setFinalizingForm(true);
    finalizeForm({ id: formId })
      .then(() => {
        setFormList(formList.map((form) => (form.id === formId ? { ...form, status: 'complete' } : form)));
      })
      .catch(console.error)
      .finally(() => setFinalizingForm(false));
  };

  const downloadForm = (formId: string, format: string) => {
    const storage = getStorage();
    const storageRef = ref(storage, `forms/${formId}.${format}`);

    if (format === 'pdf') {
      setDownloadingPdf(true);
    } else {
      setDownloadingWord(true);
    }

    getDownloadURL(storageRef)
      .then((url) => window.open(url, '_blank'))
      .catch(console.error)
      .finally(() => {
        if (format === 'pdf') {
          setDownloadingPdf(false);
        } else {
          setDownloadingWord(false);
        }
      });
  };

  /**
   * Fetches the list of admins from the database.
   */
  useEffect(() => {
    const fetchFormList = httpsCallable(getFunctions(), 'getForms');

    fetchFormList()
      .then((result) => {
        setFormList(result.data as FormModel[]);
      })
      .catch(console.error)
      .finally(() => setFetching(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const form = formList.find((form) => form.id === formId);
    if (form) {
      if (form.status === 'created' && !sendModal) {
        toggleSendModal();
      } else if (form.status === 'in-progress' && !editModal) {
        toggleEditModal();
      } else if (form.status === 'complete' && !viewModal) {
        toggleViewModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  return (
    <>
      <Container
        fluid
        title="Formularios"
        navBarActions={
          <MDBBtn color="primary" onClick={toggleSendModal} disabled={finalizingForm} className="d-inline-flex align-items-center">
            {creatingForm ? (
              <>
                <MDBSpinner size="sm" role="status" className="me-2" />
                Cargando...
              </>
            ) : (
              <>
                <MDBIcon className="me-2" icon="plus" />
                Crear
              </>
            )}
          </MDBBtn>
        }
      >
        <>
          {fetching ? (
            <LoadingSkeleton />
          ) : formList.length > 0 ? (
            <div className="overflow-hidden">
              <MDBTable responsive>
                <MDBTableHead light>
                  <tr>
                    <th>
                      <MDBIcon icon="hashtag" fas /> Código
                    </th>
                    <th className="text-center">
                      <MDBIcon icon="user" fas /> Nombre
                    </th>
                    <th className="text-center">
                      <MDBIcon icon="at" fas /> Correo
                    </th>
                    <th className="text-center">
                      <MDBIcon icon="info-circle" fas /> Estado
                    </th>
                    <th className="text-end">
                      <MDBIcon icon="cog" fas /> Acciones
                    </th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody style={{ verticalAlign: 'middle' }}>
                  {formList.map((form: FormModel) => (
                    <tr key={form.id}>
                      <td>{form.code}</td>
                      <td className="text-center">{form.fullName}</td>
                      <td className="text-center">{form.email}</td>
                      <td className="text-center">
                        <MDBBadge light color={getStatusColor(form.status)} pill>
                          {getStatusText(form.status)}
                        </MDBBadge>
                      </td>
                      <td className="text-end pe-2">
                        {form.status === 'created' && (
                          <MDBBtn
                            className="fw-bold d-inline-flex align-items-center"
                            color="link"
                            size="sm"
                            rippleColor="dark"
                            onClick={() => {
                              setFormId(form.id);
                            }}
                          >
                            <MDBIcon icon="envelope" fas className="me-2" />
                            Enviar
                          </MDBBtn>
                        )}
                        {form.status === 'sent' && (
                          <MDBBtn
                          className="fw-bold d-inline-flex align-items-center"
                            color="link"
                            size="sm"
                            rippleColor="dark"
                            onClick={() => {
                              handleResendForm(form.id);
                            }}
                            disabled={resendingForm}
                          >
                            {resendingForm ? (
                              <>
                                <MDBSpinner size="sm" role="status" className="me-2" />
                                Reenviando...
                              </>
                            ) : (
                              <>
                                <MDBIcon icon="redo-alt" fas className="me-2" />
                                Reenviar
                              </>
                            )}
                          </MDBBtn>
                        )}
                        {form.status === 'in-progress' && (
                          <>
                            <MDBBtn
                              className="fw-bold d-inline-flex align-items-center"
                              color="link"
                              size="sm"
                              rippleColor="dark"
                              onClick={() => {
                                setFormId(form.id);
                              }}
                              disabled={finalizingForm}
                            >
                              <MDBIcon icon="pencil-alt" fas className="me-2" />
                              Editar
                            </MDBBtn>
                            <MDBBtn
                              className="fw-bold d-inline-flex align-items-center text-success"
                              color="link"
                              size="sm"
                              rippleColor="dark"
                              onClick={() => {
                                handleFinalizeForm(form.id);
                              }}
                              disabled={finalizingForm}
                            >
                              {finalizingForm ? (
                                <>
                                  <MDBSpinner size="sm" role="status" className="me-2" />
                                  Finalizando...
                                </>
                              ) : (
                                <>
                                  <MDBIcon icon="check-circle" fas className="me-2" />
                                  Finalizar
                                </>
                              )}
                            </MDBBtn>
                          </>
                        )}
                        {form.status === 'complete' && (
                          <>
                            <MDBBtn
                              className="fw-bold d-inline-flex align-items-center text-success"
                              color="link"
                              size="sm"
                              rippleColor="dark"
                              onClick={() => {
                                setFormId(form.id);
                              }}
                            >
                              <MDBIcon icon="file-alt" fas className="me-2" />
                              Ver
                            </MDBBtn>
                            <MDBBtn
                              className="fw-bold d-inline-flex align-items-center"
                              color="link"
                              size="sm"
                              rippleColor="dark"
                              onClick={() => {
                                downloadForm(form.id, 'pdf');
                              }}
                            >
                              {downloadingPdf ? (
                                <>
                                  <MDBSpinner size="sm" role="status" className="me-2" />
                                  Descargando...
                                </>
                              ) : (
                                <>
                                  <MDBIcon icon="file-pdf" fas className="me-2" />
                                  PDF
                                </>
                              )}
                            </MDBBtn>
                            <MDBBtn
                              className="fw-bold d-inline-flex align-items-center"
                              color="link"
                              size="sm"
                              rippleColor="dark"
                              onClick={() => {
                                downloadForm(form.id, 'docx');
                              }}
                            >
                              {downloadingWord ? (
                                <>
                                  <MDBSpinner size="sm" role="status" className="me-2" />
                                  Descargando...
                                </>
                              ) : (
                                <>
                                  <MDBIcon icon="file-word" fas className="me-2" />
                                  WORD
                                </>
                              )}
                            </MDBBtn>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>
          ) : (
            <MDBContainer fluid className="h-100">
              <MDBRow className="h-100 justify-content-center align-items-center">
                <MDBCol className="text-center">
                  <MDBIcon className="mb-3" icon="table" size="2x" />
                  <h6 className="fw-bold">No existen formularios</h6>
                  <p className="text-muted">Crea un formluario</p>
                  <MDBBtn color="primary" onClick={toggleSendModal}>
                    {creatingForm ? (
                      <>
                        <MDBSpinner size="sm" role="status" className="me-2" />
                        Cargando...
                      </>
                    ) : (
                      <>
                        <MDBIcon className="me-2" icon="plus" />
                        Crear
                      </>
                    )}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          )}
        </>
      </Container>
      <SendFormModal open={sendModal} setOpen={setSendingModal} toggle={toggleSendModal} formId={formId ?? ''} />
      <EditFormModal open={editModal} setOpen={setEditModal} toggle={toggleEditModal} formId={formId ?? ''} />
      <ViewFormModal open={viewModal} setOpen={setViewModal} toggle={toggleViewModal} formId={formId ?? ''} />
    </>
  );
};

export default React.memo(FormsScreen);
