import { FormTemplateModel } from '@/features/templates/data/model/FormTemplateModel';
import { atom } from 'recoil';

const formTemplateState = atom<FormTemplateModel | null>({
  key: 'formTemplateState',
  default: null,
});

export { formTemplateState };

