import React from 'react';

const ErrorScreen = ({ message }: { message: string }) => {
  return (
    <div className='d-flex h-100 justify-content-center align-items-center'>
      <div>Error: {message}</div>
    </div>
  );
};

export default ErrorScreen;