import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { User } from '@/features/auth/data/models/User';

interface ProtectedRouteProps {
  user: User | null;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ user }) => {
  if (!user) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  if (!user.emailVerified) {
    return <Navigate to="/auth/verify-email" replace />;
  }

  if (!user.termsAccepted) {
    return <Navigate to="/onboarding/terms" replace />;
  }

  if (!user.profileComplete) {
    return <Navigate to="/onboarding/user-information" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
