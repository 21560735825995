import { candidateListSelector, userListState } from '@/core/hooks/useUserList';
import InitialsAvatar from '@/features/common/components/Avatar';
import Container from '@/features/common/components/Container';
import LoadingSkeleton from '@/features/common/components/LoadingSkeleron';
import { User } from '@/features/common/data/interface/User';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  MDBBadge,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdb-react-ui-kit';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * CandidatesScreen component represents the list of candidates in the application.
 * @returns The CandidatesScreen component.
 */
const CandidatesScreen: React.FC = () => {
  // Candidate List hook for state management
  const setUserList = useSetRecoilState(userListState);
  const candidateList = useRecoilValue(candidateListSelector);

  // State variables
  const [fetching, setFetching] = useState<boolean>(true);

  /**
   * Fetches the list of admins from Firebase and updates the state.
   * This is a memoized function that will be recreated only if one of its dependencies changes.
   */
  const getUserList = useCallback(async () => {
    try {
      // Calls 'getUserList' Cloud Function to fetch the list of admins
      const userListResult = await httpsCallable(getFunctions(), 'getUserList')();

      // Updates the state with the list of admins
      setUserList(userListResult.data as User[]);
    } catch (error) {
      console.error(error);
    } finally {
      // Sets the 'fetching' state to false once the operation is complete
      setFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Effect hook to fetch the list of admins on component mount.
   * This effect runs once after the component mounts.
   */
  useEffect(() => {
    getUserList(); // Invokes the getUserList function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container fluid title="Candidatos">
        <>
          {fetching ? (
            <LoadingSkeleton />
          ) : candidateList.length > 0 ? (
            <div className="overflow-hidden">
              <MDBTable>
                <MDBTableHead light>
                  <tr>
                    <th>Nombre</th>
                    <th className="text-center">Estado</th>
                    <th className="text-end">Acciones</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody style={{ verticalAlign: 'middle' }}>
                  {candidateList.map((candidate: User) => (
                    <tr key={candidate.uid}>
                      <td>
                        <div className="d-flex align-items-center">
                          <InitialsAvatar
                            name={candidate.name ? candidate.name ?? '' : candidate.email}
                            style={{ width: '45px', height: '45px', fontSize: 18 }}
                          />
                          <div className="ms-3">
                            <p className="fw-bold mb-1">{candidate.name}</p>
                            <p className="text-muted mb-0">{candidate.email}</p>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <MDBBadge
                          light
                          color={!candidate.disabled ? (candidate.profileComplete ? 'success' : 'warning') : 'danger'}
                          pill
                        >
                          {!candidate.disabled ? (candidate.profileComplete ? 'Activo' : 'Pendiente') : 'Inactivo'}
                        </MDBBadge>
                      </td>
                      <td className="text-end pe-2">
                        <MDBBtn className="fw-bold" color="link" size="sm" rippleColor="dark" onClick={() => {}}>
                          Editar
                        </MDBBtn>
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>
          ) : (
            <MDBContainer fluid className="h-100">
              <MDBRow className="h-100 justify-content-center align-items-center">
                <MDBCol className="text-center">
                  <MDBIcon className="mb-3" icon="users" size="2x" />
                  <h6 className="fw-bold">No existen candidatos</h6>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          )}
        </>
      </Container>
    </>
  );
};

export default React.memo(CandidatesScreen);
