import React from 'react';
import { MDBSpinner } from 'mdb-react-ui-kit';

/**
 * Loading screen component.
 *
 * @returns {React.ReactElement} The loading screen component.
 */
const LoadingScreen: React.FC = () => {
  return (
    <div className='d-flex h-100 justify-content-center align-items-center'>
      <MDBSpinner className='me-2' color='primary' style={{ width: '3rem', height: '3rem' }}>
        <span className='visually-hidden'>Loading...</span>
      </MDBSpinner>
    </div>
  );
};

export default React.memo(LoadingScreen);
