import { userState } from '@/core/hooks/useUser';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import { useRecoilState } from 'recoil';


/**
 * Component for displaying user personal information.
 * @returns {React.FC} The PersonalInformationCard component.
 */
const PersonalInformationCard: React.FC = () => {
  // User hook for state management
  const [user, setUser] = useRecoilState(userState);
  
  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle tag='h5' className='mb-4'>Información personal</MDBCardTitle>
        <MDBCardBody className='p-0'>
          <MDBRow>
            <MDBCol size='6'>
            <h6 className='mb-2'>Nombre</h6>
            <p className='mb-0'>{user?.name}</p>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCardBody>
    </MDBCard>
  );
};

export default React.memo(PersonalInformationCard);
