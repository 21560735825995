import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSpinner,
  MDBValidation,
  MDBValidationItem,
} from 'mdb-react-ui-kit';

import createRefHandler from '@/core/utils/createRefHandler';
import { auth } from '@/core/firebase';
import useFormValidations from '@/core/hooks/useFormValidations';

/**
 * Represents the structure of the form data for resetting a user's password.
 *
 * @type {Object} FormData
 * @property {string} email - The user's email.
 */
type FormData = {
  email: string;
};

/**
 * Component for handling the forgot password functionality.
 * Allows users to submit their email to receive a password reset link.
 * @return ForgotPasswordScreen component.
 */
const ForgotPasswordScreen: React.FC = () => {
  // Navigation hook for redirecting users
  const navigate = useNavigate();

  // useFormValidations hook initialization
  const { emailValidation } = useFormValidations();

  // Setting up state management
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setError,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { email: '' },
  });

  // Registering input fields with validation rules
  const emailRef = register('email', emailValidation()).ref;

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, data.email);
      reset({ ...data });
      setIsSaved(true);
    } catch (error: any) {
      console.error(error);
      setError('email', { type: 'custom', message: getErrorMessage(error.code) });
      reset({ ...data });
    } finally {
      setLoading(false);
    }
  };

  // Function to navigate back to the sign-in screen
  const navigateToSignIn = () => {
    navigate('/auth/sign-in');
  };

  // Function to get the appropriate error message
  const getErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      case 'auth/user-not-found':
        return 'El usuario no existe';
      case 'auth/invalid-email':
        return 'Ingresa un correo electrónico válido';
      default:
        return 'Ha ocurrido un error';
    }
  };

  // Reset save status when form is dirty
  useEffect(() => {
    if (isDirty) setIsSaved(false);
  }, [isDirty]);

  return (
    <MDBContainer className="mt-5">
      <section className="text-center">
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="12" md="8" lg="6" xl="6">
            <MDBCard>
              <MDBCardBody className="p-4">
                {!isSaved ? (
                  <>
                    <h2 className="fw-bold mb-2 text-uppercase">Recuperar contraseña</h2>
                    <p className="mb-5">Ingresa el correo electronico que utilizaste para crear tu cuenta</p>

                    <MDBValidation onSubmit={handleSubmit(onSubmit)}>
                      {/* Email Input Field */}
                      <MDBValidationItem
                        feedback={errors.email?.message || ' '}
                        invalid={!!errors.email}
                        className="mb-4"
                      >
                        <MDBInput
                          label="Correo electrónico"
                          {...register('email', emailValidation())}
                          type="email"
                          ref={createRefHandler(emailRef, errors.email)}
                        />
                      </MDBValidationItem>

                      {/* Submit Button with Loading Indicator */}
                      <MDBBtn
                        type="submit"
                        block
                        size="lg"
                        disabled={loading || !isDirty}
                        className={!!errors.email ? 'mt-3' : ''}
                      >
                        {loading ? (
                          <>
                            <MDBSpinner size="sm" role="status" className="me-2" />
                            Enviando...
                          </>
                        ) : (
                          <>Continuar</>
                        )}
                      </MDBBtn>

                      {/* Go back to sign-in screen button */}
                      <MDBBtn
                        type="button"
                        color="link"
                        rippleColor="light"
                        block
                        size="lg"
                        className="mt-3 text-transform-none fs-6"
                        onClick={navigateToSignIn}
                      >
                        Regresar
                      </MDBBtn>
                    </MDBValidation>
                  </>
                ) : (
                  <>
                    <MDBRow className="mb-5 mt-4">
                      <MDBCol md="12" className="d-flex justify-content-center">
                        <MDBIcon icon="check" fas size="4x" />
                      </MDBCol>
                    </MDBRow>

                    <h2 className="fw-bold mb-2 text-uppercase">Revisa tu correo</h2>
                    <p className="mb-4">Se ha enviado un correo con un enlace para poder reiniciar tu contraseña</p>
                    <MDBBtn type="button" block size="lg" onClick={navigateToSignIn}>
                      Continuar
                    </MDBBtn>
                  </>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  );
};

export default React.memo(ForgotPasswordScreen);
