import React from 'react';

import { formTemplateState } from '@/core/hooks/useFormTemplate';
import { MDBIcon } from 'mdb-react-ui-kit';
import { useRecoilState } from 'recoil';
import { FormFieldModel } from '../data/model/FormFieldModel';
import { FormSectionModel } from '../data/model/FormSectionModel';
import FormTemplateSection from './FormTemplateSection';

/**
 * SendFormModal component for sending a form to a candidate
 *
 * @param {FormTemplateSectionProps} props - Component props
 * @returns {React.ReactElement} - The CreateAdminModal component.
 */
const FormTemplateSections: React.FC = () => {
  const [formTemplate, setFormTemplate] = useRecoilState(formTemplateState);

  const handleChange = (formSection: FormSectionModel) => {
    setFormTemplate((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        sections: prev.sections.map((section) => (section.id === formSection.id ? formSection : section)),
      };
    });
  };

  const createNewSection = () => {
    setFormTemplate((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        sections:
          prev.sections && prev.sections.length > 0
            ? [
                ...prev.sections,
                {
                  id: (prev.sections.length + 1).toString(),
                  name: `Sección ${prev.sections.length + 1}`,
                  fields: [] as FormFieldModel[],
                  isAdminOnly: false,
                } as FormSectionModel,
              ]
            : [
                {
                  id: (1).toString(),
                  name: `Sección 1`,
                  fields: [] as FormFieldModel[],
                  isAdminOnly: false,
                } as FormSectionModel,
              ],
      };
    });
  };

  const handleDelete = (formSection: FormSectionModel) => {
    setFormTemplate((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        sections: prev.sections.filter((section) => section.id !== formSection.id),
      };
    });
  };

  return (
    <>
      {formTemplate?.sections && formTemplate?.sections.length > 0 ? (
        formTemplate?.sections.map((section) => (
          <FormTemplateSection
            key={section.id}
            formSection={section}
            onChange={handleChange}
            onDelete={() => handleDelete(section)}
          />
        ))
      ) : (
        <div className="note note-light square border-top border-bottom border-end mb-3">
          <p className="my-0 form-text">No se ha creado ninguna sección</p>
        </div>
      )}
      <div>
        <a onClick={createNewSection} href="#!" className="small">
          <MDBIcon icon="plus" className="me-2" />
          Crear sección
        </a>
      </div>
    </>
  );
};

export default React.memo(FormTemplateSections);
