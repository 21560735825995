import { User } from "@/features/common/data/interface/User";
import { atom, selector } from "recoil";

const userListState = atom<User[]>({
  key: "userList",
  default: [],
});

const adminListSelector = selector({
  key: "adminList",
  get: ({ get }) => get(userListState).filter((user: User) => user.accessLevel === "admin" || user.accessLevel === "super-admin"),
});

const candidateListSelector = selector({
  key: "candidateList",
  get: ({ get }) => get(userListState).filter((user: User) => user.accessLevel === "candidate"),
});

export {
  adminListSelector, candidateListSelector, userListState
};

