import { httpsCallable } from 'firebase/functions';
import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSpinner,
  MDBTextArea,
  MDBValidation,
  MDBValidationItem,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { functions } from '@/core/firebase';
import { formTemplateState } from '@/core/hooks/useFormTemplate';
import { formTemplateListState } from '@/core/hooks/useFormTemplateList';
import useFormValidations from '@/core/hooks/useFormValidations';
import createRefHandler from '@/core/utils/createRefHandler';
import { Timestamp } from 'firebase/firestore';
import { useRecoilState } from 'recoil';
import { FormSectionModel } from '../data/model/FormSectionModel';
import { FormTemplateModel } from '../data/model/FormTemplateModel';
import FormTemplateSections from './FormTemplateSections';

/**
 * Represents the props for the SendFormModal component.
 *
 * @property {boolean} open - Whether the modal is open or not.
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOpen - Function to set the open state.
 * @property {() => void} toggle - Function to toggle the modal.
 */
interface CreateFormTemplateModalProps {
  baseFormTemplateId?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: () => void;
}

/**
 * Represents the structure of the form data for sending a form.
 *
 * @property {string} name - The name of the template.
 * @property {string} description - The description of the template.
 * @property {FormSectionModel[]} sections - The sections of the template.
 */
type FormData = {
  name: string;
  description: string;
  sections: FormSectionModel[];
};

/**
 * SendFormModal component for sending a form to a candidate
 *
 * @param {CreateFormTemplateModalProps} props - Component props
 * @returns {React.ReactElement} - The CreateAdminModal component.
 */
const SendFormModal: React.FC<CreateFormTemplateModalProps> = ({ baseFormTemplateId, open, setOpen, toggle }) => {
  const [formTemplateList, setFormTemplateList] = useRecoilState(formTemplateListState);
  const [formTemplate, setFormTemplate] = useRecoilState(formTemplateState);
  const { notEmptyValidation } = useFormValidations();

  // Setting up state management
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | null>(null);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setValue,
    getValues,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { name: '', description: '', sections: [] },
  });

  // Registering input fields with validation rules
  const nameRef = register('name', notEmptyValidation()).ref;

  // Firebase function for updating user information
  const createFormTemplate = httpsCallable(functions, 'createFormTemplate');

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      const result = await createFormTemplate({ ...data });
      const createFormTemplateResult = result.data as FormTemplateModel;

      // Set the form template state
      setFormTemplate(createFormTemplateResult);

      // Create a new form list with the updated item
      const newFormTemplateList = [...formTemplateList, createFormTemplateResult];

      // Update the form template list state
      setFormTemplateList(newFormTemplateList);
      setFormTemplate(null);
      setGeneralError(null);
      reset();
      toggle();
    } catch (error: any) {
      console.error('Error during information update:', error);
      setGeneralError('Error al crear la plantilla');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setFormTemplate(null);
    setGeneralError(null);
    reset();
    toggle();
  };

  // Update form fields when admin data changes
  useEffect(() => {
    if (formTemplate !== null) {
      setValue('sections', formTemplate?.sections || [], {
        shouldDirty: getValues('sections') !== formTemplate?.sections,
      });
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formTemplate, setValue, reset]);

  // Reset save status when form is dirty
  useEffect(() => {
    if (isDirty) setIsSaved(false);
  }, [isDirty]);

  useEffect(() => {
    setFormTemplate({
      id: '',
      name: '',
      description: '',
      sections: formTemplateList.find((form) => form.id === baseFormTemplateId)?.sections || [],
      createdAt: Timestamp.now(),
      isBase: false,
      isActive: true,
    } as FormTemplateModel);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MDBModal tabIndex="-1" open={open} setOpen={setOpen} staticBackdrop={true} className="modal-lg">
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBValidation onSubmit={handleSubmit(onSubmit)} noValidate>
            <MDBModalHeader>
              <MDBModalTitle>Crear Plantilla De Formulario</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" type="reset" onClick={handleClose}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {/* Name Input Field */}
              <MDBValidationItem feedback={errors.name?.message || ' '} invalid={!!errors.name} className="mb-3">
                <MDBInput
                  label="Nombre"
                  {...register('name', notEmptyValidation())}
                  type="text"
                  ref={createRefHandler(nameRef, errors.name)}
                  aria-describedby="nameDescription"
                />
                <div id="nameDescription" className={!!errors.name ? 'form-text mt-4' : 'form-text'}>
                  Nombre de la plantilla
                </div>
              </MDBValidationItem>
              {/* Description Input Field */}
              <MDBValidationItem
                feedback={errors.description?.message || ' '}
                invalid={!!errors.description}
                className="mb-3"
              >
                <MDBTextArea
                  label="Descripción"
                  {...register('description', notEmptyValidation())}
                  rows={2}
                  onChange={(e) => setValue('description', e.target.value)}
                  aria-describedby="descriptionDescription"
                />
                <div id="descriptionDescription" className={!!errors.description ? 'form-text pt-3' : 'form-text'}>
                  Descripción breve de la plantilla
                </div>
              </MDBValidationItem>

              <h6 className="fw-bold mb-3">Secciones del formulario</h6>
              <FormTemplateSections />

              {/* Display General Error Message */}
              {generalError && <div className="text-danger mt-3">{generalError}</div>}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="reset" color="light" onClick={handleClose}>
                Cerrar
              </MDBBtn>
              <MDBBtn type="submit" disabled={loading || !isDirty}>
                {loading ? (
                  <>
                    <MDBSpinner size="sm" role="status" className="me-2" />
                    Creando...
                  </>
                ) : isSaved ? (
                  <>
                    <MDBIcon fas icon="check" className="me-2" />
                    Creada
                  </>
                ) : (
                  <>Crear</>
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBValidation>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default React.memo(SendFormModal);
