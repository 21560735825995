import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { User } from '@/features/auth/data/models/User';

/**
 * Props for the ConditionalRoute component.
 * @property {User | null} user - The user object or null if not authenticated.
 * @property {(user: User) => boolean} condition - A function determining if the route is accessible based on the user.
 * @property {string} redirectTo - The path to redirect to if the condition is not met.
 */
interface ConditionalRouteProps {
  user: User | null;
  condition: (user: User | null) => boolean;
  redirectTo: string;
}

/**
 * A component that conditionally renders an Outlet or redirects to a specified path.
 * It is used to manage route access based on specific conditions (e.g., user authentication).
 *
 * @param {ConditionalRouteProps} props - The properties including the user, condition, and the redirect path.
 * @returns {React.ReactElement} - Either an Outlet or a Navigate component based on the given condition.
 */
const ConditionalRoute: React.FC<ConditionalRouteProps> = ({ user, condition, redirectTo }) => {
  if (!user) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  if (condition(user)) {
    return <Outlet />;
  }

  return <Navigate to={redirectTo} replace />;
};

export default ConditionalRoute;
