import Container from '@/features/common/components/Container';
import { MDBCard, MDBCardBody, MDBChart, MDBCol, MDBDatepicker, MDBRow, MDBSelect } from 'mdb-react-ui-kit';
import React from 'react';

/**
 * DashboardScreen component represents the main dashboard view in the application.
 * @returns The DashboardScreen component.
 */
const DashboardScreen: React.FC = () => {
  return (
    <Container title="Inicio" fluid={false}>
      <section className="mb-4">
        <MDBCard>
          <MDBCardBody className="p-4">
            <MDBRow>
              <MDBCol size="12" md="6" lg="6">
                <MDBSelect
                  label="Fecha"
                  data={[
                    { text: 'Hoy', value: '1' },
                    { text: 'Ayer', value: '2' },
                    { text: 'Últimos 7 días', value: '3' },
                    { text: 'Últimos 30 días', value: '4' },
                    { text: 'Últimos 90 días', value: '5' },
                  ]}
                />
              </MDBCol>
              <MDBCol size="12" md="6" lg="6">
                <MDBDatepicker
                  title="Fecha"
                  inputLabel="Fecha"
                  monthsFull={[
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre',
                  ]}
                  monthsShort={['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']}
                  weekdaysFull={['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']}
                  weekdaysShort={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
                  weekdaysNarrow={['S', 'M', 'D', 'M', 'D', 'F', 'S']}
                  okBtnText="Aceptar"
                  clearBtnText="Limpiar"
                  cancelBtnText="Cancelar"
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </section>
      <MDBRow className="mb-4">
        <MDBCol size="12" md="6" lg="3">
          <MDBCard>
            <MDBCardBody>
              <p className="text-uppercase small mb-2">
                <strong>OPEN POSITIONS</strong>
              </p>
              <h5 className="mb-0">
                <strong>24</strong>
                <small className="text-success ms-2">
                  <i className="fas fa-arrow-up fa-sm pe-1"></i>8.33%
                </small>
              </h5>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol size="12" md="6" lg="3">
          <MDBCard>
            <MDBCardBody>
              <p className="text-uppercase small mb-2">
                <strong>TOTAL PROFIT/LOSS</strong>
              </p>
              <h5 className="mb-0">
                <strong>$12,345.67</strong>
                <small className="text-danger ms-2">
                  <i className="fas fa-arrow-down fa-sm pe-1"></i>2.15%
                </small>
              </h5>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol size="12" md="6" lg="3">
          <MDBCard>
            <MDBCardBody>
              <p className="text-uppercase small mb-2">
                <strong>WIN RATE</strong>
              </p>
              <h5 className="mb-0">
                <strong>62.5%</strong>
                <small className="text-success ms-2">
                  <i className="fas fa-arrow-up fa-sm pe-1"></i>3.8%
                </small>
              </h5>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol size="12" md="6" lg="3">
          <MDBCard>
            <MDBCardBody>
              <p className="text-uppercase small mb-2">
                <strong>AVERAGE TRADE DURATION</strong>
              </p>
              <h5 className="mb-0">
                <strong>3h 45m</strong>
                <small className="text-info ms-2">
                  <i className="fas fa-minus fa-sm pe-1"></i>0.5%
                </small>
              </h5>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12">
          <MDBChart
            type="line"
            data={{
              labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
              datasets: [
                {
                  label: 'Traffic',
                  data: [2112, 2343, 2545, 3423, 2365, 1985, 987],
                },
              ],
            }}
          />
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default React.memo(DashboardScreen);
