import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';

import Container from '@/features/common/components/Container';
import PersonalInformationCard from '@/features/profile/components/PersonalInformationCard';

/**
 * Component for editting user profile, allowing users to edit their name, email and phone.
 * @returns {React.FC} The ProfileEditScreen component.
 */
const ProfileEditScreen: React.FC = () => {
  return (
    <Container title="Configuración">
      <section className="px-4 pt-4">
        <MDBRow>
          <MDBCol size="12">
            <PersonalInformationCard />
          </MDBCol>
        </MDBRow>
      </section>
    </Container>
  );
};

export default React.memo(ProfileEditScreen);
