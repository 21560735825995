import { User } from '@/features/auth/data/models/User';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

/**
 * PublicRouteProps is the props for the PublicRoute component.
 *
 * @property {User | null} user - The user object.
 */
interface PublicRouteProps {
  user: User | null;
}

/**
 * PublicRoute is a component that renders the Outlet if the user is not logged in.
 * If the user is logged in, it redirects to the home page.
 *
 * @param props - The props for the PublicRoute component.
 * @param props.user - The user object.
 * @return The PublicRoute component.
 */
const PublicRoute: React.FC<PublicRouteProps> = ({ user }) => {
  return user ? <Navigate to="/" replace /> : <Outlet />;
};

export default PublicRoute;
