import { MDBBtn } from 'mdb-react-ui-kit';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { userState } from '@/core/hooks/useUser';
import Container from '@/features/common/components/Container';
import { useRecoilState } from 'recoil';

/**
 * Not found screen component.
 *
 * @returns {React.ReactElement} The not found screen component.
 */
const NotFoundScreen: React.FC = () => {
  // Navigation hooks
  const navigate = useNavigate();

  // Get the current user
  const [user] = useRecoilState(userState);

  /**
   * Handles the go back button click event.
   * Navigates to the home page.
   */
  const handleGoBack = () => {
    navigate('/dashboard');
  };

  // JSX Content for the not found page
  const notFoundContent = (
    <div className="d-flex h-100 justify-content-center align-items-center">
      <section className="my-5 text-center">
        <h1 className="display-1">404</h1>
        <h4 className="mb-4">Página no encontrada</h4>
        <p className="mb-0">La página que estás buscando no existe o ha ocurrido un error.</p>
        <p className="mb-4">Regresa, o dirígete al inicio para elegir una nueva dirección.</p>
        <MDBBtn color="primary" onClick={handleGoBack}>
          Regresar al inicio
        </MDBBtn>
      </section>
    </div>
  );

  return user && (user?.accessLevel === 'super-admin' || user?.accessLevel === 'admin') ? (
    <Container title="">{notFoundContent}</Container>
  ) : (
    notFoundContent
  );
};

export default React.memo(NotFoundScreen);
