import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSelect,
  MDBSpinner,
  MDBValidation,
  MDBValidationItem,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { functions } from '@/core/firebase';
import { useFormList } from '@/core/hooks/useFormList';
import { formTemplateListState } from '@/core/hooks/useFormTemplateList';
import useFormValidations from '@/core/hooks/useFormValidations';
import createRefHandler from '@/core/utils/createRefHandler';
import { FormTemplateModel } from '@/features/templates/data/model/FormTemplateModel';
import { SelectData } from 'mdb-react-ui-kit/dist/types/pro/forms/Select/types';
import { useRecoilState } from 'recoil';
import { FormModel } from '../data/model/FormModel';

/**
 * Represents the props for the SendFormModal component.
 */
interface SendFormModalProps {
  formId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: () => void;
}
interface SendFormModalProps {
  formId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: () => void;
}

/**
 * Represents the structure of the form data for sending a form.
 */
interface FormData {
  fullName: string;
  email: string;
  phone: string;
  templateId: string;
}

/**
 * SendFormModal component for sending a form to a candidate
 *
 * @param {SendFormModalProps} props - Component props
 * @returns {React.ReactElement} - The CreateAdminModal component.
 */
const SendFormModal: React.FC<SendFormModalProps> = ({ formId, open, setOpen, toggle }: SendFormModalProps): React.ReactElement => {
  const { formList, setFormList } = useFormList();
  const { emailValidation, nameValidation, phoneValidation } = useFormValidations();

  // Form Lisdt hook for state management
  const [formTemplateList, setFormTemplateList] = useRecoilState(formTemplateListState);

  // Setting up state management
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [, setFetching] = useState<boolean>(true);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setValue,
    getValues,
    setError,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { fullName: '', email: '', phone: '', templateId: '' },
  });

  // Registering input fields with validation rules
  const fullNameRef = register('fullName', nameValidation()).ref;
  const emailRef = register('email', emailValidation()).ref;
  const phoneRef = register('phone', phoneValidation()).ref;

  // Firebase function for updating user information
  const sendForm = httpsCallable(functions, 'sendForm');

  // Function to reset form and methods after 250ms
  const resetForm = () => {
    setTimeout(() => {
      reset();
    }, 250);
  };

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      const result = await sendForm({ id: formId, ...data });
      const updatedForm = result.data as FormModel;

      // Find the index of the form being updated
      const index = formList.findIndex((form) => form.id === formId);

      // Create a new form list with the updated item
      const newFormList = [...formList];
      if (index !== -1) {
        newFormList[index] = updatedForm;
      } else {
        // If the form isn't found, it's a new form, so add it to the list
        newFormList.push(updatedForm);
      }

      setFormList(newFormList);
      resetForm();
      toggle();
    } catch (error: any) {
      console.error('Error during information update:', error);
      setError('email', { type: 'custom', message: 'Error al enviar el formulario' });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    resetForm();
    toggle();
  };

  // Reset save status when form is dirty
  useEffect(() => {
    if (isDirty) setIsSaved(false);
  }, [isDirty]);

  /**
   * Fetches the list of admins from the database.
   */
  useEffect(() => {
    const fetchFormTemplateList = httpsCallable(getFunctions(), 'getFormTemplates');

    fetchFormTemplateList()
      .then((result) => {
        setFormTemplateList(result.data as FormTemplateModel[]);
      })
      .catch(console.error)
      .finally(() => setFetching(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MDBModal tabIndex="-1" open={open} setOpen={setOpen} staticBackdrop={true}>
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBValidation onSubmit={handleSubmit(onSubmit)} noValidate>
            <MDBModalHeader>
              <MDBModalTitle>Enviar Formulario</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" type="reset" onClick={handleClose}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {/* Name Input Field */}
              <MDBValidationItem
                feedback={errors.fullName?.message || ' '}
                invalid={!!errors.fullName}
                className="mb-3"
              >
                <MDBInput
                  label="Nombre"
                  {...register('fullName', nameValidation())}
                  type="text"
                  ref={createRefHandler(fullNameRef, errors.fullName)}
                  aria-describedby="nameDescription"
                />
                <div id="nameDescription" className={!!errors.fullName ? 'form-text mt-4' : 'form-text'}>
                  Nombre y apellido del candidato
                </div>
              </MDBValidationItem>
              {/* Email Input Field (Editable) */}
              <MDBValidationItem feedback={errors.email?.message || ' '} invalid={!!errors.email} className="mb-3">
                <MDBInput
                  label="Correo electrónico"
                  {...register('email', emailValidation())}
                  type="email"
                  ref={createRefHandler(emailRef, errors.email)}
                  aria-describedby="textExample1"
                />
                <div id="textExample1" className={!!errors.email ? 'form-text pt-3' : 'form-text'}>
                  Correo electrónico del candidato
                </div>
              </MDBValidationItem>
              {/* Phone Input Field */}
              <MDBValidationItem feedback={errors.phone?.message || ' '} invalid={!!errors.phone} className="mb-3">
                <MDBInput
                  label="Teléfono"
                  {...register('phone', phoneValidation())}
                  type="tel"
                  ref={createRefHandler(phoneRef, errors.phone)}
                  aria-describedby="phoneDescription"
                />
                <div id="phoneDescription" className={!!errors.phone ? 'form-text mt-4' : 'form-text'}>
                  Número de teléfono del candidato
                </div>
              </MDBValidationItem>
              {/* Type Input Field */}
              <MDBValidationItem
                feedback={errors.templateId?.message || ' '}
                invalid={!!errors.templateId}
                className="mb-3"
              >
                <MDBSelect
                  label="Plantilla"
                  type="text"
                  onChange={(e) => setValue('templateId', (e as SelectData).value?.toString() ?? '')}
                  aria-describedby="templateId"
                  data={formTemplateList
                    .filter((form) => !form.isBase)
                    .map((template) => ({ text: template.name, value: template.id }))}
                  value={getValues().templateId}
                />
                <div id="templateId" className={!!errors.templateId ? 'form-text' : 'form-text'}>
                  Plantilla del formulario a enviar
                </div>
              </MDBValidationItem>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="reset" color="light" onClick={handleClose}>
                Cerrar
              </MDBBtn>
              <MDBBtn type="submit" disabled={loading || !isDirty}>
                {loading ? (
                  <>
                    <MDBSpinner size="sm" role="status" className="me-2" />
                    Enviando...
                  </>
                ) : isSaved ? (
                  <>
                    <MDBIcon fas icon="check" className="me-2" />
                    Enviado
                  </>
                ) : (
                  <>Enviar</>
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBValidation>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default React.memo(SendFormModal);
