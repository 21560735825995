import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import SignInCard from '../components/SignInCard';
import SignInForm from '../components/SignInForm';
import SocialAuthButtons from '../components/SocialAuthButtons';

/**
 * Component for handling sign-in functionality.
 * Allows users to sign-in with their email and password, or with Google.
 * @return SignInScreen component.
 */
const SignInScreen: React.FC = () => {
  // Navigation hook for redirecting users
  const navigate = useNavigate();

  // Function to navigate back to the sign-in screen
  const navigateToForgotPassword = () => {
    navigate('/auth/forgot-password');
  };

  return (
    <MDBContainer className="d-flex align-items-center justify-content-center py-5" style={{ minHeight: '100vh' }}>
      <section className="w-100">
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="12" md="8" lg="6" xl="6">
            <SignInCard>
              <SignInForm onForgotPassword={navigateToForgotPassword} />
              <SocialAuthButtons isSignUp={false} />
            </SignInCard>
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  );
};

export default SignInScreen;
