import { functions } from '@/core/firebase';
import { httpsCallable } from 'firebase/functions';
import { MDBBtn, MDBIcon, MDBInput, MDBSpinner, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { auth } from '@/core/firebase';
import useFormValidations from '@/core/hooks/useFormValidations';
import createRefHandler from '@/core/utils/createRefHandler';
import { createUserWithEmailAndPassword } from 'firebase/auth';

/**
 * Interface for the properties passed to the SignUpForm component.
 * @interface SignUpFormProps
 * @property {Function} onSignUp - Function to handle sign-up action.
 */
interface SignUpFormProps {
  onSignUp: (data: MainFormData) => void;
}

/**
 * Type for the main form data.
 * @type {Object} MainFormData
 * @property {string} email - The user's email.
 * @property {string} password - The user's password.
 */
type MainFormData = {
  email: string;
  password: string;
};

/**
 * Type for the referrer form data.
 * @type {Object} ReferrerFormData
 * @property {string} referrer - The referrer code.
 */
type ReferrerFormData = {
  referrer: string;
};

/**
 * Component for the sign-up form.
 * @param props - The properties passed to the component.
 * @param props.onSignUp - Function to handle sign-up action.
 * @return SignUpForm component.
 */
const SignUpForm: React.FC<SignUpFormProps> = ({ onSignUp }) => {
  // Translation hook
  const { t } = useTranslation();

  // Validation rules hook
  const { emailValidation, passwordValidation, referralCodeValidation } = useFormValidations();

  // Loading state
  const [loading, setLoading] = useState<boolean>(false);

  // Main form
  const {
    register: registerMain,
    handleSubmit: handleSubmitMain,
    formState: { errors: mainErrors, isDirty: isMainDirty },
    reset: resetMain,
    setError: setMainError,
  } = useForm<MainFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { email: '', password: '' },
  });

  // Referrer form
  const {
    register: registerReferrer,
    formState: { errors: referrerErrors },
    getValues: getReferrerValues,
    handleSubmit: handleSubmitReferrer,
  } = useForm<ReferrerFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: { referrer: '' },
  });

  // References for the main form fields
  const emailRef = registerMain('email', emailValidation()).ref;
  const passwordRef = registerMain('password', passwordValidation()).ref;

  // Reference for the referrer form field
  const referrerRef = registerReferrer('referrer', referralCodeValidation()).ref;

  // Handle main form submission
  const onSubmitMain = async (data: MainFormData) => {
    setLoading(true);
    try {
      // Create user with email and password
      await createUserWithEmailAndPassword(auth, data.email, data.password);

      // If user is created successfully, register the referrer if provided
      const referrerCode = getReferrerValues().referrer;
      if (referrerCode && !referrerErrors.referrer) {
        const registerReferral = httpsCallable(functions, 'registerReferral');
        await registerReferral({ referrer: referrerCode });
        console.log('Referral registered successfully');
      }

      // Call onSignUp with the form data
      onSignUp(data);
    } catch (error: any) {
      handleSignUpError(error, data);
    } finally {
      setLoading(false);
    }
  };

  // Handle sign-up error
  const handleSignUpError = (error: any, data: MainFormData) => {
    const errorMappings: any = {
      'auth/email-already-in-use': { field: 'email', message: t('auth.signUp.emailInUse') },
      'auth/invalid-email': { field: 'email', message: t('auth.signUp.invalidEmail') },
      'auth/weak-password': { field: 'password', message: t('auth.signUp.weakPassword') },
    };

    const defaultError = { field: 'email', message: t('auth.signUp.genericError') };
    const { field, message } = errorMappings[error.code] || defaultError;

    console.error(error);
    resetMain({ ...data });
    setMainError(field, { type: 'custom', message });
  };

  // Handle referrer form submission
  const onSubmitReferrer = (data: ReferrerFormData) => {
    console.log(data);
  };

  // Handle referrer form change
  const handleReferrerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value) {
      handleSubmitReferrer(onSubmitReferrer)();
    }
  };

  return (
    <>
      {/* Main form */}
      <MDBValidation onSubmit={handleSubmitMain(onSubmitMain)} id="signUpForm">
        <MDBValidationItem feedback={mainErrors.email?.message || ' '} invalid={!!mainErrors.email} className="mb-4">
          <MDBInput
            label={t('auth.signUp.email')}
            {...registerMain('email', emailValidation())}
            type="email"
            ref={createRefHandler(emailRef, mainErrors.email)}
          />
        </MDBValidationItem>

        <MDBValidationItem
          feedback={mainErrors.password?.message || ' '}
          invalid={!!mainErrors.password}
          className={mainErrors.email ? 'pt-2 mb-4' : 'mb-4'}
        >
          <MDBInput
            label={t('auth.signUp.password')}
            {...registerMain('password', passwordValidation())}
            type="password"
            ref={createRefHandler(passwordRef, mainErrors.password)}
          />
        </MDBValidationItem>
      </MDBValidation>

      {/* Referrer form */}
      <MDBValidation id="referrerForm" onSubmit={handleSubmitReferrer(onSubmitReferrer)}>
        <MDBValidationItem
          feedback={referrerErrors.referrer?.message || ' '}
          invalid={!!referrerErrors.referrer}
          className={mainErrors.password ? 'pt-2 mb-4' : 'mb-4'}
        >
          <MDBInput
            label={t('auth.signUp.referrer')}
            {...registerReferrer('referrer', referralCodeValidation())}
            ref={createRefHandler(referrerRef, referrerErrors.referrer)}
            onChange={handleReferrerChange}
            type="text"
          />
        </MDBValidationItem>
      </MDBValidation>

      {/* Sign-up button */}
      <MDBBtn
        type="submit"
        block
        form="signUpForm"
        size="lg"
        disabled={loading || !isMainDirty}
        className="d-flex align-items-center justify-content-between mt-4 mb-4"
      >
        {loading ? (
          <>
            {t('auth.signUp.signingUp')}
            <MDBSpinner size="sm" role="status" className="ms-auto" />
          </>
        ) : (
          <>
            {t('auth.signUp.signUp')}
            <MDBIcon fas icon="arrow-right" className="ms-auto" />
          </>
        )}
      </MDBBtn>
    </>
  );
};

export default SignUpForm;
