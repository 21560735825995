import { formState, initialFormState } from '@/core/hooks/useForm';
import useFormValidations from '@/core/hooks/useFormValidations';
import { FormTemplateModel } from '@/features/templates/data/model/FormTemplateModel';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { MDBBtn, MDBCheckbox, MDBIcon, MDBSpinner, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import FormHeader from '../components/FormHeader';
import FormSection from '../components/FormSection';
import { FormModel } from '../data/model/FormModel';

/**
 * Represents the structure of the form data for sending a form.
 */
interface FormData {
  acceptTerms: boolean;
}

/**
 * FormsScreen component represents the list of forms in the application.
 * @returns The FormsScreen component.
 */
const FormScreen: React.FC = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  // Navigation hooks
  const navigate = useNavigate();

  const [fetchingForm, setFetchingForm] = useState(false);
  const [form, setForm] = useRecoilState<FormModel | null>(formState);
  const [formTemplate, setFormTemplate] = useState<FormTemplateModel>();

  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  // Form validations
  const { notEmptyValidation } = useFormValidations();

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
    watch,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { acceptTerms: false },
  });

  const formData = useRecoilValue(formState);
  const methods = useForm({ mode: 'onSubmit' });

  const handleFormReset = () => {
    methods.reset();
    setForm({ ...initialFormState, ...form, formData: {} });
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      const submitForm = httpsCallable(getFunctions(), 'submitForm');
      await submitForm({ id, data: formData?.formData });
      setIsSaved(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check if the form is fetching
    if (fetchingForm) {
      return;
    }

    // Check if the form has an id
    if (id) {
      setFetchingForm(true);
    }

    // Get the form https callable
    const fetchForm = httpsCallable(getFunctions(), 'getFormById');

    // Get the form data
    fetchForm({ id }).then(async (result) => {
      // Set the form data
      setForm(result.data as FormModel);

      // Set the fetching form to false
      setFetchingForm(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    // Check if the form has an id and a templateId
    if (form && form.id && form.templateId) {
      // Get the form template https callable
      const fetchFormTemplate = httpsCallable(getFunctions(), 'getFormTemplateById');

      // Get the form template
      fetchFormTemplate({ id: form.templateId }).then((result) => {
        // Set the form template
        setFormTemplate(result.data as FormTemplateModel);
      });
    }
  }, [form, token]);

  return (
    <MDBValidation onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="container my-3">
        <div className="row mb-3">
          <div className="col-6 mx-auto">
            <FormHeader form={form} />
          </div>
        </div>
        <FormProvider {...methods}>
          {form &&
            formTemplate &&
            formTemplate.sections.map((section) => (
              <div className="row mb-3" key={section.id}>
                <div className="col-6 mx-auto">
                  <FormSection key={section.id} section={section} />
                </div>
              </div>
            ))}
        </FormProvider>
        <div className="row mb-3">
          <MDBValidationItem
            className="col-6 mx-auto d-flex"
            feedback={errors.acceptTerms?.message || ' '}
            invalid={!!errors.acceptTerms}
          >
            <MDBCheckbox
              {...register(
                'acceptTerms',
                notEmptyValidation(true, 'Debes aceptar los términos de uso y procesamiento de la información')
              )}
              label=""
              name="acceptTerms"
              required
              className={!!errors.acceptTerms && errors.acceptTerms.message !== ' ' ? 'mb-3' : ''}
              onChange={() => {
                setValue('acceptTerms', !getValues('acceptTerms'), { shouldDirty: true });
              }}
              checked={watch('acceptTerms')}
            />
            <p
              className="m-0"
              role="button"
              onClick={() => {
                setValue('acceptTerms', !getValues('acceptTerms'), { shouldDirty: true });
              }}
            >
              Acepto los{' '}
              <a className="text-underline" href="/terminos" onClick={() => navigate('/terminos')}>
                términos de uso y procesamiento de la información
              </a>
            </p>
          </MDBValidationItem>
        </div>
        <div className="row">
          <div className="col-6 mx-auto d-flex justify-content-between">
            <MDBBtn
              type="submit"
              disabled={loading || !(methods.formState.isDirty || isDirty)}
              className="d-inline-flex align-items-center"
            >
              {loading ? (
                <>
                  <MDBSpinner size="sm" role="status" className="me-2" />
                  Enviando...
                </>
              ) : isSaved ? (
                <>
                  <MDBIcon fas icon="check" className="me-2" />
                  Enviado
                </>
              ) : (
                <>Enviar</>
              )}
            </MDBBtn>
            <MDBBtn type="reset" color="link" onClick={handleFormReset}>
              Limpiar formulario
            </MDBBtn>
          </div>
        </div>
      </div>
    </MDBValidation>
  );
};

export default React.memo(FormScreen);
