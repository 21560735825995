import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon } from 'mdb-react-ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/**
 * Interface for the properties passed to the SignInCard component.
 * @interface SignInCardProps
 * @property {React.ReactNode} children - The content to be displayed within the card.
 */
interface SignInCardProps {
  children: React.ReactNode;
}

/**
 * Component for displaying a sign-in card with a title, sign-up link, and description.
 * @param props - The properties passed to the component.
 * @param props.children - The content to be displayed within the card.
 * @return SignInCard component.
 */
const SignInCard: React.FC<SignInCardProps> = ({ children }) => {
  // Translation hook
  const { t } = useTranslation();

  return (
    <MDBCard>
      <MDBCardTitle tag="div" className="border-bottom d-flex justify-content-between align-items-center p-4 m-0">
        <h2 className="fw-bold m-0">{t('auth.signIn.title')}</h2>
        <Link to="/auth/sign-up">
          <MDBBtn tag="button" noRipple color="link" className="p-0 d-flex align-items-center">
            <MDBIcon fas icon="exchange-alt" className="me-2" />
            {t('auth.signIn.signUpLink')}
          </MDBBtn>
        </Link>
      </MDBCardTitle>
      <MDBCardBody className="p-0">
        <div className="p-4">
          <p className="mb-4 text-muted">{t('auth.signIn.description')}</p>
          {children}
        </div>
      </MDBCardBody>
    </MDBCard>
  );
};

export default SignInCard;
