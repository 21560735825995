import { MDBBtn, MDBCheckbox, MDBInput, MDBSelect, MDBValidationItem } from 'mdb-react-ui-kit';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import useFormValidations from '@/core/hooks/useFormValidations';
import createRefHandler from '@/core/utils/createRefHandler';
import { SelectData } from 'mdb-react-ui-kit/dist/types/pro/forms/Select/types';
import { FieldType } from '../data/model/FIeldType';
import { FormFieldModel } from '../data/model/FormFieldModel';

/**
 * Represents the props for the SendFormModal component.
 *
 * @typedef {Object} FormTemplateSectionProps
 * @property {FormFieldModel} formField - The form section to be created.
 * @property {(formSection: FormSectionModel) => void} onChange - Function to handle the change in the form section.
 * @property {() => void} onDelete - Function to handle the deletion of the form section.
 */
interface FormTemplateSectionProps {
  formField: FormFieldModel;
  onChange: (formField: FormFieldModel) => void;
  onDelete: () => void;
}

/**
 * Represents the structure of the form data for sending a form.
 *
 * @typedef {Object} FormData
 * @property {string} name - The name of the template.
 * @property {boolean} isAdminOnly - Indicates if the section is for admin use only
 */
type FormData = {
  id: string;
  label: string;
  type: string;
  options: string;
  isRequired: boolean;
  isMultiple: boolean;
  isAdminOnly: boolean;
};

/**
 * SendFormModal component for sending a form to a candidate
 *
 * @param {FormTemplateSectionProps} props - Component props
 * @returns {React.ReactElement} - The CreateAdminModal component.
 */
const FormTemplateSection: React.FC<FormTemplateSectionProps> = ({ formField, onChange, onDelete }) => {
  const { notEmptyValidation } = useFormValidations();

  // useForm hook initialization with form validation rules
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      id: formField.id,
      label: formField.label,
      type: formField.type,
      options: formField.options,
      isRequired: formField.isRequired,
      isMultiple: formField.isMultiple,
      isAdminOnly: formField.isAdminOnly,
    },
  });

  // Registering input fields with validation rules
  const labelRef = register('label', notEmptyValidation()).ref;
  const optionsRef = register('options', notEmptyValidation()).ref;

  useEffect(() => {
    const formData = watch();

    if (
      formData.id !== formField.id ||
      formData.label !== formField.label ||
      formData.type !== formField.type ||
      formData.options !== formField.options ||
      formData.isRequired !== formField.isRequired ||
      formData.isMultiple !== formField.isMultiple ||
      formData.isAdminOnly !== formField.isAdminOnly
    ) {
      onChange({
        ...formField,
        id: formData.id,
        label: formData.label,
        type: formData.type as FieldType,
        options: formData.options,
        isRequired: formData.isRequired,
        isMultiple: formData.isMultiple,
        isAdminOnly: formData.isAdminOnly,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  return (
    <div className="note note-light border-top border-bottom border-end pt-3 mb-3">
      {/* Name Input Field */}
      <MDBValidationItem feedback={errors.label?.message || ' '} invalid={!!errors.label} className="mb-3">
        <MDBInput
          label="Nombre"
          {...register('label', notEmptyValidation())}
          type="text"
          ref={createRefHandler(labelRef, errors.label)}
          aria-describedby={`nameDescription-${formField.id}`}
        />
        <div id={`nameDescription-${formField.id}`} className={!!errors.label ? 'form-text mt-4' : 'form-text'}>
          Nombre del campo
        </div>
      </MDBValidationItem>
      {/* Type Input Field */}
      <MDBValidationItem feedback={errors.type?.message || ' '} invalid={!!errors.type} className="mb-3">
        <MDBSelect
          label="Tipo"
          type="text"
          onChange={(e) => setValue('type', (e as SelectData).value?.toString() ?? '')}
          aria-describedby={`typeDescription-${formField.id}`}
          data={[
            { text: 'Texto', value: 'text' },
            { text: 'Número', value: 'number' },
            { text: 'Fecha', value: 'date' },
            { text: 'Email', value: 'email' },
            { text: 'Teléfono', value: 'phone' },
            { text: 'Imagen', value: 'photoUpload' },
            { text: 'Opciones', value: 'select' },
          ]}
          value={getValues().type}
        />
        <div id={`typeDescription-${formField.id}`} className={!!errors.type ? 'form-text mt-4' : 'form-text'}>
          Tipo de campo
        </div>
      </MDBValidationItem>
      {/* When the type is select, show MDBChipsInput allowing the user to add multiple options */}
      {getValues().type === 'select' && (
        <MDBValidationItem feedback={errors.options?.message || ' '} invalid={!!errors.options} className="mb-3">
          <MDBInput
            label="Opciones"
            {...register('options', notEmptyValidation())}
            type="text"
            ref={createRefHandler(optionsRef, errors.options)}
            aria-describedby="optionsDescription"
          />
          <div id="optionsDescription" className={!!errors.options ? 'form-text mt-4' : 'form-text'}>
            Opciones del campo, separadas por comas
          </div>
        </MDBValidationItem>
      )}
      {/* Is Required Input Field */}
      <MDBValidationItem feedback={errors.isRequired?.message || ' '} invalid={!!errors.isRequired} className="mb-3">
        <MDBCheckbox
          label="Campo Requerido"
          onChange={(e) => {
            if (getValues().isRequired !== e.target.checked) {
              setValue('isRequired', e.target.checked);
            }
          }}
          checked={getValues().isRequired}
          id={`isRequired-${formField.id}`}
          name={`isRequired-${formField.id}`}
        />
      </MDBValidationItem>
      {/* Is Multiple Input Field */}
      {getValues().type !== 'select' && (
        <MDBValidationItem feedback={errors.isMultiple?.message || ' '} invalid={!!errors.isMultiple} className="mb-3">
          <MDBCheckbox
            label="Campo Con Multiples Respuestas"
            onChange={() => {
              setValue('isMultiple', !getValues().isMultiple);
            }}
            checked={getValues().isMultiple}
            id={`isMultiple-${formField.id}`}
            name={`isMultiple-${formField.id}`}
          />
        </MDBValidationItem>
      )}
      {/* Is Only Admin Input Field */}
      <MDBValidationItem feedback={errors.isAdminOnly?.message || ' '} invalid={!!errors.isAdminOnly} className="mb-3">
        <MDBCheckbox
          label="Solo administradores"
          onChange={() => {
            setValue('isAdminOnly', !getValues().isAdminOnly);
          }}
          checked={getValues().isAdminOnly}
          id={`isAdminOnly-${formField.id}`}
          name={`isAdminOnly-${formField.id}`}
        />
      </MDBValidationItem>

      <hr className="hr-primary" />

      <MDBBtn type="button" onClick={() => onDelete()} color="danger" size="sm">
        Eliminar Campo
      </MDBBtn>
    </div>
  );
};

export default React.memo(FormTemplateSection);
