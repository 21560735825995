import { MDBBtn, MDBCheckbox, MDBInput, MDBValidationItem } from 'mdb-react-ui-kit';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import useFormValidations from '@/core/hooks/useFormValidations';
import createRefHandler from '@/core/utils/createRefHandler';
import { FormSectionModel } from '../data/model/FormSectionModel';
import FormTemplateFields from './FormTemplateFields';

/**
 * Represents the props for the SendFormModal component.
 *
 * @typedef {Object} FormTemplateSectionProps
 * @property {FormSectionModel} formSection - The form section to be created.
 * @property {(formSection: FormSectionModel) => void} onChange - Function to handle the change in the form section.
 * @property {() => void} onDelete - Function to handle the deletion of the form section.
 */
interface FormTemplateSectionProps {
  formSection: FormSectionModel;
  onChange: (formSection: FormSectionModel) => void;
  onDelete: () => void;
}

/**
 * Represents the structure of the form data for sending a form.
 *
 * @typedef {Object} FormData
 * @property {string} name - The name of the template.
 * @property {boolean} isAdminOnly - Indicates if the section is for admin use only
 */
type FormData = {
  name: string;
  isAdminOnly: boolean;
};

/**
 * SendFormModal component for sending a form to a candidate
 *
 * @param {FormTemplateSectionProps} props - Component props
 * @returns {React.ReactElement} - The CreateAdminModal component.
 */
const FormTemplateSection: React.FC<FormTemplateSectionProps> = ({ formSection, onChange, onDelete }) => {
  const { notEmptyValidation } = useFormValidations();

  // useForm hook initialization with form validation rules
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { name: formSection.name, isAdminOnly: formSection.isAdminOnly },
  });

  // Registering input fields with validation rules
  const nameRef = register('name', notEmptyValidation()).ref;

  useEffect(() => {
    const formData = watch();

    if (formData.name !== formSection.name || formData.isAdminOnly !== formSection.isAdminOnly) {
      onChange({ ...formSection, name: formData.name, isAdminOnly: formData.isAdminOnly });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  return (
    <div className="note note-light border-top border-bottom border-end pt-3 mb-3">
      {/* Name Input Field */}
      <MDBValidationItem feedback={errors.name?.message || ' '} invalid={!!errors.name} className="mb-3">
        <MDBInput
          label="Nombre"
          {...register('name', notEmptyValidation())}
          type="text"
          ref={createRefHandler(nameRef, errors.name)}
          aria-describedby="nameDescription"
        />
        <div id="nameDescription" className={!!errors.name ? 'form-text mt-4' : 'form-text'}>
          Nombre de la sección
        </div>
      </MDBValidationItem>
      {/* Description Input Field */}
      <MDBValidationItem feedback={errors.isAdminOnly?.message || ' '} invalid={!!errors.isAdminOnly} className="mb-3">
        <MDBCheckbox
          label="Solo administradores"
          className="text-dark"
          onChange={() => {
            setValue('isAdminOnly', !getValues().isAdminOnly);
          }}
          name={`isAdminOnly-${formSection.id}`}
          id={`isAdminOnly-${formSection.id}`}
          checked={getValues().isAdminOnly}
        />
      </MDBValidationItem>

      <h6 className="fw-bold mb-3">Campos de la sección</h6>
      <FormTemplateFields formSection={formSection} />

      <hr className="hr-primary mb-0" />

      <MDBBtn type="button" onClick={() => onDelete()} color="danger" size="sm" className="mt-3">
        Eliminar Sección
      </MDBBtn>
    </div>
  );
};

export default React.memo(FormTemplateSection);
