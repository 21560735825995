import { FormTemplateModel } from '@/features/templates/data/model/FormTemplateModel';
import { atom } from 'recoil';

const formTemplateListState = atom<FormTemplateModel[]>({
  key: 'formTemplateListState',
  default: [],
});

export { formTemplateListState };

