import { MDBInput } from 'mdb-react-ui-kit';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { InputProps } from '../../../../typings/mdb-react-ui-kit';

interface InputMaskProps extends Omit<InputProps, 'ref'> {
  charPlaceholder?: string;
  clearIncomplete?: boolean;
  customMask?: string;
  customValidator?: string;
  inputMask?: string;
  inputPlaceholder?: boolean;
  label?: React.ReactNode;
  maskPlaceholder?: boolean;
  onComplete?: (value: string) => any;
  onInputChange?: (value: string) => any;
}

const defaultMasks = {
  '#': { validator: /\d/, symbol: '#' },
  a: { validator: /[a-zżźąćśńółę]/i, symbol: 'a' },
  '*': { validator: /[a-zżźąćśńółę0-9]/i, symbol: '*' },
};

const InputMask = forwardRef<HTMLInputElement, InputMaskProps>(
  (
    {
      charPlaceholder = '_',
      clearIncomplete = true,
      customMask,
      customValidator,
      inputMask,
      inputPlaceholder = true,
      label,
      maskPlaceholder,
      onComplete,
      onInputChange,
      ...props
    },
    ref
  ): React.ReactElement => {
    const localInputRef = useRef<HTMLInputElement | null>(null);

    const setLocalInputRef = useCallback((el: HTMLInputElement | null) => {
      localInputRef.current = el;
    }, []);

    useImperativeHandle(ref, () => localInputRef.current as HTMLInputElement);

    const [value, setValue] = useState('');
    const [masks, setMasks] = useState(defaultMasks);
    const [fixedChars, setFixedChars] = useState<string[]>([]);
    const [placeholder, setPlaceholder] = useState<string>();
    const [isComplete, setIsComplete] = useState(false);

    const padValue = useCallback(
      (value: string): string => {
        if (!placeholder) return value;
        return value + placeholder.slice(value.length);
      },
      [placeholder]
    );

    const formatValue = useCallback(
      (input: string): string => {
        if (!inputMask) return input;

        // Existing logic for handling input (unchanged)
        const escapedFixedChars = fixedChars.map((char) => char.replace(/[.*+?^${}()|[\]\\-]/g, '\\$&')).join('');

        const inputChars = input.replace(new RegExp(`[^\\d${escapedFixedChars}]`, 'g'), '').split('');
        let formattedValue = '';
        let inputIndex = 0;

        for (let i = 0; i < inputMask.length; i++) {
          if (fixedChars[i]) {
            formattedValue += fixedChars[i];
            if (inputChars[inputIndex] === fixedChars[i]) {
              inputIndex++;
            }
          } else if (inputIndex < inputChars.length) {
            const maskChar = inputMask[i];
            const inputChar = inputChars[inputIndex];
            if (masks[maskChar as keyof typeof masks]?.validator.test(inputChar)) {
              formattedValue += inputChar;
              inputIndex++;
            } else {
              break;
            }
          } else {
            break;
          }
        }

        return formattedValue;
      },
      [inputMask, masks, fixedChars]
    );

    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        const cursorPosition = e.target.selectionStart || 0;
        const isDeleting = input.length < value.length;

        const formattedValue = formatValue(input);
        setIsComplete(formattedValue.length === inputMask?.length);
        onInputChange?.(formattedValue);

        // Pad the formatted value with the remaining mask characters
        const paddedValue = padValue(formattedValue);

        setValue(paddedValue);

        // Calculate the new cursor position
        let newCursorPosition;
        if (isDeleting) {
          // Find the first difference between old and new value
          const diffIndex = value.split('').findIndex((char, index) => char !== paddedValue[index]);
          newCursorPosition = diffIndex !== -1 ? diffIndex : cursorPosition;
        } else {
          newCursorPosition = formattedValue.length;
        }

        // Set the cursor position after the state has been updated
        setTimeout(() => {
          e.target.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
      },
      [value, inputMask, formatValue, onInputChange, setIsComplete, padValue]
    );

    const handleBlur = useCallback(() => {
      if (!clearIncomplete || !inputMask) return;
      if (maskPlaceholder) {
        const placeholderChars = charPlaceholder?.split('');
        const nonFixedPlaceholders = placeholderChars?.filter((char) => fixedChars.indexOf(char) === -1);
        if (value.split('').filter((char) => nonFixedPlaceholders?.includes(char)).length > 0) {
          setValue('');
        }
      }
      if (value.length !== inputMask.length) {
        setValue('');
      }
    }, [charPlaceholder, clearIncomplete, fixedChars, inputMask, maskPlaceholder, value]);

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
          // Find the index of the first editable character in the mask
          const firstEditableIndex =
            inputMask?.split('').findIndex((char, index) => {
              const isEditable = Object.keys(masks).includes(char);
              const isFixed = fixedChars[index];
              return isEditable && !isFixed;
            }) ?? 0;

          // Set the cursor to the position right after the prefix
          setTimeout(() => {
            e.target.setSelectionRange(firstEditableIndex, firstEditableIndex);
          }, 0);
        }
      },
      [inputMask, masks, fixedChars]
    );

    const maskExists = useCallback(
      (mask: string): boolean => {
        return mask in masks;
      },
      [masks]
    );

    const initCustomMasks = useCallback(() => {
      const customMaskArray = customMask?.split(',');
      const customValidatorArray = customValidator?.split(',');

      if (!customMaskArray || !customValidatorArray) return;

      customMaskArray.forEach((mask, index) => {
        const validator = customValidatorArray[index];
        if (!validator || !isValidRegex(validator) || maskExists(mask)) return;

        setMasks((prevMasks) => ({
          ...prevMasks,
          [mask]: {
            validator: new RegExp(validator),
            symbol: mask,
          },
        }));
      });
    }, [customMask, customValidator, maskExists]);

    const initFixedChars = useCallback(() => {
      const chars: string[] = [];
      inputMask?.split('').forEach((char) => {
        if (maskExists(char)) {
          chars.push('');
        } else {
          chars.push(char);
        }
      });
      setFixedChars(chars);
    }, [inputMask, maskExists]);

    const initPlaceholder = useCallback(() => {
      if (charPlaceholder?.length === 1) {
        const placeholderChars = fixedChars.map((char) => char || charPlaceholder);
        setPlaceholder(placeholderChars.join(''));
      } else {
        setPlaceholder(charPlaceholder);
      }
    }, [charPlaceholder, fixedChars]);

    const isValidRegex = (regex: string): boolean => {
      try {
        new RegExp(regex);
        return true;
      } catch {
        return false;
      }
    };

    useEffect(() => {
      initCustomMasks();
      initFixedChars();
    }, [initCustomMasks, initFixedChars, masks, maskExists]);

    useEffect(() => {
      initPlaceholder();
    }, [initPlaceholder, maskExists]);

    useEffect(() => {
      isComplete && onComplete?.(value);
    }, [value, isComplete, onComplete]);

    return (
      <MDBInput
        {...props}
        type="text"
        label={label}
        ref={(el) => {
          setLocalInputRef(el);
          if (typeof ref === 'function') {
            ref(el);
          } else if (ref) {
            (ref as React.MutableRefObject<HTMLInputElement | null>).current = el;
          }
        }}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    );
  }
);

InputMask.displayName = 'InputMask';

export { InputMask };
export type { InputMaskProps };

