import { FormModel } from "@/features/form/data/model/FormModel";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdb-react-ui-kit";

type FormHeaderProps = {
  form: FormModel | null;
};

const FormHeader: React.FC<FormHeaderProps> = ({ form }) => {
  return <>
    <MDBCard className="border border-top-primary border-1 border-top-5">
      <MDBCardHeader className="border-1">
        <h1>Trabajos Guate</h1>
        <p className="mb-0">Formulario de solicitud de empleo</p>
        </MDBCardHeader>
      <MDBCardBody>
        <p className="mb-0"><span className="fw-bold">{form?.fullName}</span> {form?.email}</p>
        <p className="mb-0 text-danger">* Requeridos</p>
      </MDBCardBody>
    </MDBCard>
  </>;
};

export default FormHeader;

