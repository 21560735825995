import React from 'react';
import '@/features/common/components/Avatar.scss';

/**
 * Props for the InitialsAvatar component.
 */
export interface InitialsAvatarProps {
  name?: string;
  className?: string;
  style?: React.CSSProperties;
}

/**
 * Extracts the initials from the provided name.
 * @param {string} name - The full name from which initials are derived.
 * @returns {string} The initials from the name.
 */
function getInitials(name: string | undefined): string {
  return name?.charAt(0).toUpperCase() ?? "";
}

/**
 * InitialsAvatar component that displays the first initial of a given name.
 *
 * @param {InitialsAvatarProps} props - The props for the component.
 * @returns The InitialsAvatar component.
 */
const InitialsAvatar: React.FC<InitialsAvatarProps> = ({ name, className, style }) => {
  return (
    <div aria-label={name} role="img" className={'initials-avatar ' + className} style={style}>
      <span>{getInitials(name)}</span>
    </div>
  );
};

export default React.memo(InitialsAvatar);
