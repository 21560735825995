import React from 'react';

import { formTemplateState } from '@/core/hooks/useFormTemplate';
import { MDBIcon } from 'mdb-react-ui-kit';
import { useRecoilState } from 'recoil';
import { FormFieldModel } from '../data/model/FormFieldModel';
import { FormSectionModel } from '../data/model/FormSectionModel';
import FormTemplateField from './FormTemplateField';

/**
 * Represents the props for the SendFormModal component.
 *
 * @typedef {Object} FormTemplateSectionProps
 * @property {FormSectionModel} formSection - The form section to be created.
 */
interface FormTemplateFieldsProps {
  formSection: FormSectionModel;
}

/**
 * SendFormModal component for sending a form to a candidate
 *
 * @param {FormTemplateSectionProps} props - Component props
 * @returns {React.ReactElement} - The CreateAdminModal component.
 */
const FormTemplateFields: React.FC<FormTemplateFieldsProps> = ({ formSection }) => {
  const [formTemplate, setFormTemplate] = useRecoilState(formTemplateState);

  const createField = () => {
    setFormTemplate((prevFormTemplate) => {
      if (!prevFormTemplate) return null;

      const updatedFormTemplate = {
        ...prevFormTemplate,
        sections: prevFormTemplate.sections.map((section) => {
          if (section.id === formSection.id) {
            return {
              ...section,
              fields: [
                ...section.fields,
                {
                  id: (section.fields.length + 1).toString(),
                  label: `Campo ${section.fields.length + 1}`,
                  type: 'text',
                  isRequired: false,
                  isMultiple: false,
                  isAdminOnly: false,
                } as FormFieldModel,
              ],
            };
          }
          return section;
        }),
      };

      return updatedFormTemplate;
    });
  };

  const handleChangeField = (formField: FormFieldModel) => {
    setFormTemplate((prevFormTemplate) => {
      if (!prevFormTemplate) return null;

      const updatedFormTemplate = {
        ...prevFormTemplate,
        sections: prevFormTemplate.sections.map((section) => {
          if (section.id === formSection.id) {
            return {
              ...section,
              fields: section.fields.map((field) => (field.id === formField.id ? formField : field)),
            };
          }
          return section;
        }),
      };

      return updatedFormTemplate;
    });
  };

  const handleDeleteField = (formField: FormFieldModel) => {
    setFormTemplate((prevFormTemplate) => {
      if (!prevFormTemplate) return null;

      const updatedFormTemplate = {
        ...prevFormTemplate,
        sections: prevFormTemplate.sections.map((section) => {
          if (section.id === formSection.id) {
            return {
              ...section,
              fields: section.fields.filter((field) => field.id !== formField.id),
            };
          }
          return section;
        }),
      };

      return updatedFormTemplate;
    });
  };

  return (
    <>
      {((formTemplate?.sections.find((section: FormSectionModel) => section.id === formSection.id)?.fields &&
        formTemplate?.sections.find((section: FormSectionModel) => section.id === formSection.id)?.fields?.length) ||
        0) > 0 ? (
        formTemplate?.sections
          .find((section: FormSectionModel) => section.id === formSection.id)
          ?.fields.map((field: FormFieldModel) => (
            <FormTemplateField
              key={field.id}
              formField={field}
              onChange={handleChangeField}
              onDelete={() => handleDeleteField(field)}
            />
          ))
      ) : (
        <div className="note note-light border-top border-bottom border-end mb-3">
          <p className="my-0 form-text">No se ha creado ningún campo</p>
        </div>
      )}
      <div>
        <a onClick={createField} href="#!" className="small">
          <MDBIcon icon="plus" className="me-2" />
          Crear campo
        </a>
      </div>
    </>
  );
};

export default React.memo(FormTemplateFields);
