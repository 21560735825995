import { functions } from '@/core/firebase';
import { formTemplateListState } from '@/core/hooks/useFormTemplateList';
import { userState } from '@/core/hooks/useUser';
import Container from '@/features/common/components/Container';
import LoadingSkeleton from '@/features/common/components/LoadingSkeleron';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  MDBBadge,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBRow,
  MDBSpinner,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import CreateFormTemplateModal from '../components/CreateFormTemplateModal';
import EditFormTemplateModal from '../components/EditFormTemplateModal';
import { FormTemplateModel } from '../data/model/FormTemplateModel';

/**
 * TemplateListScreen component represents the list of templates in the application.
 * @returns The TemplateListScreen component.
 */
const FormTemplateListScreen: React.FC = () => {
  // User hook
  const [user] = useRecoilState(userState);

  // Form List hook for state management
  const [formTemplateList, setFormTemplateList] = useRecoilState(formTemplateListState);

  // State variables
  const [fetching, setFetching] = useState<boolean>(true);
  const [creatingFormTemplate, setCreatingFormTemplate] = useState<boolean>(false);
  const [editModal, setEditModal] = useState(false);
  const [formTemplateId, setFormTemplateId] = useState<string | undefined>(undefined);
  const [deleting, setDeleting] = useState<string>();
  const [activating, setActivating] = useState<string>();
  const [settingBase, setSettingBase] = useState<string>();

  /**
   * Toggles the create form modal.
   */
  const toggleCreateModal = () => setCreatingFormTemplate(!creatingFormTemplate);

  /**
   * Toggles the edit form modal.
   */
  const toggleEditModal = () => {
    if (editModal) {
      setFormTemplateId(undefined);
    }
    return setEditModal(!editModal);
  };

  // Firebase function for updating user information
  const deleteFormTemplate = httpsCallable(functions, 'deleteFormTemplateById');

  const handleDelete = async (formTemplateId: string) => {
    setDeleting(formTemplateId);
    try {
      await deleteFormTemplate({ id: formTemplateId });

      // Create a new form list with the updated item
      const newFormTemplateList = formTemplateList.filter((form) => form.id !== formTemplateId);

      // Update the form template list state
      setFormTemplateList(newFormTemplateList);
    } catch (error: any) {
      console.error('Error deleting form template:', error);
    } finally {
      setFormTemplateId(undefined);
      setDeleting('');
    }
  };

  // Firebase function for activating a form template
  const toggleIsActiveFormTemplate = httpsCallable(functions, 'toggleIsActiveFormTemplateById');

  const handleActivate = async (formTemplateId: string) => {
    setActivating(formTemplateId);
    try {
      await toggleIsActiveFormTemplate({ id: formTemplateId });

      // Create a new form list with the updated item
      const newFormTemplateList = formTemplateList.map((form) =>
        form.id === formTemplateId ? { ...form, isActive: !form.isActive } : form
      );

      // Update the form template list state
      setFormTemplateList(newFormTemplateList);
    } catch (error: any) {
      console.error('Error activating form template:', error);
    } finally {
      setFormTemplateId(undefined);
      setActivating('');
    }
  };

  // Firebase function for setting a form template as base
  const setBaseFormTemplate = httpsCallable(functions, 'setBaseFormTemplateById');

  const handleSetBase = async (formTemplateId: string) => {
    setSettingBase(formTemplateId);
    try {
      await setBaseFormTemplate({ id: formTemplateId });

      const newFormTemplateList = formTemplateList.map((form) =>
        form.id === formTemplateId ? { ...form, isBase: true } : { ...form, isBase: false }
      );

      // Update the form template list state
      setFormTemplateList(newFormTemplateList);
    } catch (error: any) {
      console.error('Error setting form template as base:', error);
    } finally {
      setFormTemplateId(undefined);
      setSettingBase('');
    }
  };

  /**
   * Fetches the list of admins from the database.
   */
  useEffect(() => {
    const fetchFormTemplateList = httpsCallable(getFunctions(), 'getFormTemplates');

    fetchFormTemplateList()
      .then((result) => {
        setFormTemplateList(result.data as FormTemplateModel[]);
      })
      .catch(console.error)
      .finally(() => setFetching(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        fluid
        title="Plantillas de formularios"
        navBarActions={
          <MDBBtn color="primary" onClick={toggleCreateModal}>
            <MDBIcon className="me-2" icon="plus" />
            Crear
          </MDBBtn>
        }
      >
        <>
          {fetching ? (
            <LoadingSkeleton />
          ) : formTemplateList.length > 0 ? (
            <div className="overflow-hidden">
              <MDBTable>
                <MDBTableHead light>
                  <tr>
                    <th>
                      <MDBIcon icon="wpforms" fab /> Plantilla
                    </th>
                    <th>
                      <MDBIcon icon="info-circle" fas /> Estado
                    </th>
                    <th className="text-end">
                      <MDBIcon icon="cog" fas /> Acciones
                    </th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody style={{ verticalAlign: 'middle' }}>
                  {formTemplateList.map((form: FormTemplateModel) => (
                    <tr key={form.id}>
                      <td>
                        <p className="fw-bold mb-1">{form.name}</p>
                        <p className="text-muted mb-0">{form.description}</p>
                      </td>
                      <td>
                        <MDBBadge light color={form.isActive ? 'success' : 'warning'} pill>
                          {form.isActive ? 'Activo' : 'Inactivo'}
                        </MDBBadge>
                        {user?.accessLevel === 'super-admin' && form.isBase && (
                          <MDBBadge light color="info" pill className="ms-2">
                            Plantilla base
                          </MDBBadge>
                        )}
                      </td>
                      <td className="text-end pe-2">
                        <MDBBtn
                          className="fw-bold"
                          color="link"
                          size="sm"
                          rippleColor="dark"
                          onClick={() => {
                            setFormTemplateId(form.id);
                            toggleEditModal();
                          }}
                        >
                          Editar
                        </MDBBtn>
                        {user?.accessLevel === 'super-admin' && !form.isBase && (
                          <>
                            <MDBPopconfirm
                              btnChildren={
                                settingBase !== '' && settingBase === form.id ? (
                                  <>
                                    <MDBSpinner size="sm" role="status" className="me-2" />
                                    Actualizando...
                                  </>
                                ) : (
                                  <>Definir plantilla base</>
                                )
                              }
                              btnClassName={`btn-link ripple-surface-dark btn-sm fw-bold d-inline-flex align-items-center text-info`}
                              placement="bottom"
                              confirmBtnText="Definir"
                              cancelBtnText="Cancelar"
                              cancelBtnClasses="btn-light"
                              className="shadow-4-strong border"
                              onConfirm={() => {
                                handleSetBase(form.id);
                              }}
                            >
                              <MDBPopconfirmMessage>
                                Deseas definir esta plantilla como plantilla base?
                              </MDBPopconfirmMessage>
                            </MDBPopconfirm>
                            <MDBPopconfirm
                              btnChildren={
                                activating !== '' && activating === form.id ? (
                                  <>
                                    <MDBSpinner size="sm" role="status" className="me-2" />
                                    {form.isActive ? 'Desactivando...' : 'Activando...'}
                                  </>
                                ) : (
                                  <>{form.isActive ? 'Desactivar' : 'Activar'}</>
                                )
                              }
                              btnClassName={`btn-link ripple-surface-dark btn-sm fw-bold d-inline-flex align-items-center ${form.isActive ? 'text-warning' : 'text-success'}`}
                              placement="bottom"
                              confirmBtnText={form.isActive ? 'Desactivar' : 'Activar'}
                              cancelBtnText="Cancelar"
                              cancelBtnClasses="btn-light"
                              className="shadow-4-strong border"
                              onConfirm={() => {
                                handleActivate(form.id);
                              }}
                            >
                              <MDBPopconfirmMessage>
                                {form.isActive ? 'Deseas desactivar esta plantilla?' : 'Deseas activar esta plantilla?'}
                              </MDBPopconfirmMessage>
                            </MDBPopconfirm>
                            <MDBPopconfirm
                              btnChildren={
                                deleting !== '' && deleting === form.id ? (
                                  <>
                                    <MDBSpinner size="sm" role="status" className="me-2" />
                                    Eliminando...
                                  </>
                                ) : (
                                  <>Eliminar</>
                                )
                              }
                              btnClassName="btn-link ripple-surface-dark btn-sm fw-bold d-inline-flex align-items-center text-danger"
                              placement="bottom"
                              confirmBtnText="Eliminar"
                              cancelBtnText="Cancelar"
                              cancelBtnClasses="btn-light"
                              className="shadow-4-strong border"
                              onConfirm={() => {
                                handleDelete(form.id);
                              }}
                            >
                              <MDBPopconfirmMessage>Deseas eliminar esta plantilla?</MDBPopconfirmMessage>
                            </MDBPopconfirm>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>
          ) : (
            <MDBContainer fluid className="h-100">
              <MDBRow className="h-100 justify-content-center align-items-center">
                <MDBCol className="text-center">
                  <MDBIcon fab className="mb-3" icon="wpforms" size="2x" />
                  <h6 className="fw-bold">No existen plantillas de formularios</h6>
                  <p className="text-muted">Crea una plantilla de formulario</p>
                  <MDBBtn color="primary" onClick={toggleCreateModal}>
                    <MDBIcon className="me-2" icon="plus" />
                    Crear
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          )}
        </>
      </Container>
      {creatingFormTemplate && (
        <CreateFormTemplateModal
          baseFormTemplateId={formTemplateList.find((form) => form.isBase)?.id}
          open={creatingFormTemplate}
          setOpen={setCreatingFormTemplate}
          toggle={toggleCreateModal}
        />
      )}
      {editModal && (
        <EditFormTemplateModal
          open={editModal}
          setOpen={setEditModal}
          toggle={toggleEditModal}
          formTemplateId={formTemplateId}
        />
      )}
    </>
  );
};

export default React.memo(FormTemplateListScreen);
