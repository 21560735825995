import { functions } from '@/core/firebase';
import { userState } from '@/core/hooks/useUser';
import { User } from '@/features/auth/data/models/User';
import { httpsCallable } from 'firebase/functions';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBScrollbar,
  MDBSpinner,
} from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

/**
 * FormsScreen component represents the list of forms in the application.
 * @returns The FormsScreen component.
 */
const TermsScreen: React.FC = () => {
  // Translation hook
  const { t } = useTranslation();

  // User hook for state management
  const [user, setUser] = useRecoilState(userState);

  // Navigation hook for redirecting users
  const navigate = useNavigate();

  const [isAccepting, setIsAccepting] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [generalError, setGeneralError] = useState<string | null>(null);

  const handleAcceptTerms = async () => {
    setIsAccepting(true);
    try {
      const acceptTermsFunction = httpsCallable(functions, 'acceptTerms');
      await acceptTermsFunction();
      setIsAccepted(true);
      setUser({ ...user, termsAccepted: true } as User);
      // Handle successful acceptance (e.g., navigate to next screen)
      setTimeout(() => navigate('/onboarding/user-information'));
    } catch (error) {
      console.error('Error accepting terms:', error);
      setGeneralError(t('onboarding.terms.errorAcceptingTerms'));
    } finally {
      setIsAccepting(false);
    }
  };

  return (
    <MDBContainer className="py-5">
      <section>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="12" md="8" lg="6" xl="6">
            <MDBCard>
              <MDBCardBody className="p-0">
                <h2 className="fw-bold mb-2 text-uppercase text-center pt-4 px-4">{t('onboarding.terms.title')}</h2>
                <p className="mb-4 text-center px-4">{t('onboarding.terms.description')}</p>
                <MDBScrollbar
                  style={{ position: 'relative', width: '100%', height: '600px' }}
                  className="square border-top border-bottom p-4"
                >
                  <Markdown>{t('onboarding.terms.content')}</Markdown>
                </MDBScrollbar>
                <div className="text-center p-4">
                  <MDBBtn
                    onClick={handleAcceptTerms}
                    disabled={isAccepting || isAccepted}
                    size="lg"
                    block
                    className="d-flex align-items-center justify-content-between"
                  >
                    {isAccepting ? (
                      <>
                        {t('onboarding.terms.acceptingTerms')}
                        <MDBSpinner size="sm" role="status" className="ms-auto" />
                      </>
                    ) : isAccepted ? (
                      <>
                        {t('onboarding.terms.termsAccepted')}
                        <MDBIcon fas icon="check" className="ms-2" />
                      </>
                    ) : (
                      <>
                        {t('onboarding.terms.acceptButton')}
                        <MDBIcon fas icon="arrow-right" className="ms-2" />
                      </>
                    )}
                  </MDBBtn>
                  {generalError && (
                    <div className="text-danger text-start mt-3">
                      <MDBIcon fas icon="exclamation-triangle" className="me-2" />
                      {generalError}
                    </div>
                  )}
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  );
};

export default React.memo(TermsScreen);
