import React from 'react';

/**
 * LoadingSkeleton component represents a loading skeleton.
 * 
 * @returns {React.ReactElement} - The LoadingSkeleton component.
 */
const LoadingSkeleton: React.FC = () => {
  return (
    <div className="container-fluid py-3 d-flex h-100 w-100 loading-skeleton">
      <form className='w-100'>
          <div className="form-group mb-3">
            <label>Email address</label>
            <input type="email" className="form-control mt-3" />
          </div>
          <div className="form-group mb-3">
            <label>Email address</label>
            <input type="email" className="form-control mt-3" />
          </div>
          <div className="form-group mb-3">
            <label>Email address</label>
            <input type="email" className="form-control mt-3" />
          </div>
          <div className="form-group mb-3">
            <label>Email address</label>
            <input type="email" className="form-control mt-3" />
          </div>
          <div className="form-group">
            <label>Email address</label>
            <input type="email" className="form-control mt-3" />
          </div>
        </form>
    </div>
  );
};

export default React.memo(LoadingSkeleton);
