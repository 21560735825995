import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { User } from '@/features/auth/data/models/User';

interface AdminRouteProps {
  user: User | null;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ user }) => {
  if (user?.accessLevel !== 'super-admin') {
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
};

export default AdminRoute;
