import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import '@/App.scss';
import { auth } from '@/core/firebase';
import { userState } from '@/core/hooks/useUser';

import AdminRoute from '@/core/components/AdminRoute';
import ConditionalRoute from '@/core/components/ConditionalRoute';
import ProtectedRoute from '@/core/components/ProtectedRoute';
import AdminsScreen from '@/features/admins/screens/AdminsScreen';
import { User } from '@/features/auth/data/models/User';
import ForgotPasswordScreen from '@/features/auth/screens/ForgotPasswordScreen';
import SignInScreen from '@/features/auth/screens/SignInScreen';
import SignUpScreen from '@/features/auth/screens/SignUpScreen';
import VerifyEmailScreen from '@/features/auth/screens/VerifyEmailScreen';
import CandidatesScreen from '@/features/candidates/screens/CandidatesScreen';
import ErrorScreen from '@/features/common/screens/ErrorScreen';
import LoadingScreen from '@/features/common/screens/LoadingScreen';
import NotFoundScreen from '@/features/common/screens/NotFoundScreen';
import DashboardScreen from '@/features/dashboard/screens/DashboardScreen';
import FormsScreen from '@/features/form/screens/FormListScreen';
import FormScreen from '@/features/form/screens/FormScreen';
import TermsScreen from '@/features/onboarding/screens/TermsScreen';
import UserInformationScreen from '@/features/onboarding/screens/UserInformationScreen';
import ProfileScreen from '@/features/profile/screens/ProfileScreen';
import FormTemplateListScreen from '@/features/templates/screens/FormTemplateListScreen';
import PublicRoute from './core/components/PublicRoute';

/**
 * Main application component responsible for routing and authentication handling.
 * Manages user state and navigates to appropriate screens based on authentication status.
 *
 * @returns {React.ReactElement} The main application component.
 */
const App: React.FC = (): React.ReactElement => {
  // State to handle user-related errors
  const [userError, setUserError] = useState<Error | null>(null);

  // Firebase authentication state
  const [authUser, authLoading, authError] = useAuthState(auth);

  // Recoil state for managing user data
  const [user, setUser] = useRecoilState(userState);

  /**
   * Effect to fetch user model data when authentication state changes.
   * Updates both Recoil state and local state upon fetching user data.
   */
  useEffect(() => {
    if (authUser) {
      const me = httpsCallable(getFunctions(), 'me');
      me()
        .then((result) => {
          setUser(result.data as User);
        })
        .catch((e) => {
          setUserError(e);
          setUser(null);
          auth.signOut();
          window.location.reload();
        });
    } else {
      setUser(null);
    }
  }, [authUser, setUser]);

  // Render error screen in case of authentication or user-related errors
  if (authError || userError) {
    return <ErrorScreen message={authError?.message || userError?.message || 'Unknown error'} />;
  }

  // Render loading screen during authentication process or user model fetching
  if (authLoading || (authUser && !user)) {
    return <LoadingScreen />;
  }

  // Main routing logic based on user authentication and profile status
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/auth/sign-in" element={<PublicRoute user={user} />}>
          <Route path="/auth/sign-in" element={<SignInScreen />} />
        </Route>
        <Route path="/auth/sign-up" element={<PublicRoute user={user} />}>
          <Route path="/auth/sign-up" element={<SignUpScreen />} />
        </Route>
        <Route path="/auth/forgot-password" element={<PublicRoute user={user} />}>
          <Route path="/auth/forgot-password" element={<ForgotPasswordScreen />} />
        </Route>

        {/* Auth check route */}
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="/" element={<DashboardScreen />} />
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/candidates" element={<CandidatesScreen />} />
          <Route path="/forms" element={<FormsScreen />} />
          <Route path="/templates" element={<FormTemplateListScreen />} />

          {/* Admin-only route */}
          <Route element={<AdminRoute user={user} />}>
            <Route path="/admins" element={<AdminsScreen />} />
          </Route>
        </Route>

        {/* Conditional routes */}
        <Route element={<ConditionalRoute user={user} condition={(u) => !u?.emailVerified} redirectTo="/" />}>
          <Route path="/auth/verify-email" element={<VerifyEmailScreen />} />
        </Route>

        <Route element={<ConditionalRoute user={user} condition={(u) => !u?.termsAccepted} redirectTo="/" />}>
          <Route path="/onboarding/terms" element={<TermsScreen />} />
        </Route>

        <Route element={<ConditionalRoute user={user} condition={(u) => !u?.profileComplete} redirectTo="/" />}>
          <Route path="/onboarding/user-information" element={<UserInformationScreen />} />
        </Route>

        {/* Public form route */}
        <Route path="/f/:id" element={<FormScreen />} />

        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </Router>
  );
};

// Memoize the App component to prevent unnecessary re-renders
export default React.memo(App);
