import { httpsCallable } from 'firebase/functions';
import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSpinner,
  MDBTextArea,
  MDBValidation,
  MDBValidationItem,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { functions } from '@/core/firebase';
import { formTemplateState } from '@/core/hooks/useFormTemplate';
import { formTemplateListState } from '@/core/hooks/useFormTemplateList';
import useFormValidations from '@/core/hooks/useFormValidations';
import createRefHandler from '@/core/utils/createRefHandler';
import { useRecoilState } from 'recoil';
import { FormSectionModel } from '../data/model/FormSectionModel';
import FormTemplateSections from './FormTemplateSections';

/**
 * Represents the props for the EditAdminModal component.
 *
 * @typedef {Object} EditAdminModalProps
 * @property {string} formTemplateId - The form template's id.
 * @property {boolean} open - Whether the modal is open or not.
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOpen - Function to set the open state.
 * @property {() => void} toggle - Function to toggle the modal.
 */
interface EditFormTemplateModalProps {
  formTemplateId?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: () => void;
}

/**
 * Represents the structure of the form data for updating a form template.
 *
 * @typedef {Object} FormData
 * @property {string} name - The form template's name.
 * @property {string} description - The form template's description.
 */
type FormData = {
  name: string;
  description: string;
  sections: FormSectionModel[];
};

/**
 * EditFormTemplateModal component for updating a form template.
 *
 * @param {EditFormTemplateModalProps} props - Component props
 * @returns {React.ReactElement} - The EditFormTemplateModal component.
 */
const EditFormTemplateModal: React.FC<EditFormTemplateModalProps> = ({ formTemplateId, open, setOpen, toggle }) => {
  // Admin list hook for state management
  const [formTemplateList, setFormTemplateList] = useRecoilState(formTemplateListState);
  const [formTemplate, setFormTemplate] = useRecoilState(formTemplateState);

  // Form validation rules hook
  const { notEmptyValidation } = useFormValidations();

  // Setting up state management
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | null>(null);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: formTemplate?.name || '',
      description: formTemplate?.description || '',
      sections: formTemplate?.sections || [],
    },
  });

  // Registering input fields with validation rules
  const nameRef = register('name', notEmptyValidation()).ref;

  // Firebase function for updating the form template info
  const updateFormTemplate = httpsCallable(functions, 'updateFormTemplateById');

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);

    try {
      // Update user information
      await updateFormTemplate({ id: formTemplate?.id, ...data });

      // Update admin list
      setFormTemplateList(
        formTemplateList.map((formTemplateItem) =>
          formTemplateItem.id === formTemplate?.id ? { ...formTemplateItem, ...data } : formTemplateItem
        )
      );

      // Reset form and close modal
      reset({ ...data });
      toggle();
    } catch (error: any) {
      console.error('Error during form template update:', error);
      setGeneralError('Error al actualizar la información de la plantilla');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setFormTemplate(null);
    setGeneralError(null);
    toggle();
    reset();
  };

  // Set admin when form template id changes
  useEffect(() => {
    setFormTemplate(formTemplateList.find((formTemplate) => formTemplate.id === formTemplateId) || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formTemplateList, formTemplateId]);

  // Update form fields when admin data changes
  useEffect(() => {
    if (formTemplate !== null) {
      setValue('name', formTemplate?.name || '');
      setValue('description', formTemplate?.description || '');
      setValue('sections', formTemplate?.sections || [], {
        shouldDirty: getValues('sections') !== formTemplate?.sections,
      });
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formTemplate, setValue, reset]);

  // Reset save status when form is dirty
  useEffect(() => {
    if (isDirty) setIsSaved(false);
  }, [isDirty]);

  return (
    <MDBModal tabIndex="-1" open={open} setOpen={setOpen} staticBackdrop={true} className="modal-lg">
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBValidation onSubmit={handleSubmit(onSubmit)} noValidate>
            <MDBModalHeader>
              <MDBModalTitle>Editar Plantilla</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" type="reset" onClick={handleClose}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <h6 className="fw-bold mb-3">Información de la plantilla</h6>

              {/* Name Input Field */}
              <MDBValidationItem feedback={errors.name?.message || ' '} invalid={!!errors.name} className="mb-3">
                <MDBInput
                  label="Nombre"
                  {...register('name', notEmptyValidation())}
                  defaultValue={formTemplate?.name}
                  type="text"
                  ref={createRefHandler(nameRef, errors.name)}
                  aria-describedby="nameDescription"
                />
                <div id="nameDescription" className={!!errors.name ? 'form-text mt-4' : 'form-text'}>
                  Nombre de la plantilla
                </div>
              </MDBValidationItem>
              {/* Description Input Field */}
              <MDBValidationItem
                feedback={errors.description?.message || ' '}
                invalid={!!errors.description}
                className="mb-3"
              >
                <MDBTextArea
                  label="Descripción"
                  {...register('description', notEmptyValidation())}
                  defaultValue={formTemplate?.description}
                  rows={2}
                  onChange={(e) => setValue('description', e.target.value)}
                  aria-describedby="descriptionDescription"
                />
                <div id="descriptionDescription" className={!!errors.description ? 'form-text mt-4' : 'form-text'}>
                  Descripción breve de la plantilla
                </div>
              </MDBValidationItem>

              <h6 className="fw-bold mb-3">Secciones del formulario</h6>
              <FormTemplateSections />

              {/* Display General Error Message */}
              {generalError && <div className="text-danger mt-3">{generalError}</div>}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="reset" color="light" onClick={handleClose}>
                Cerrar
              </MDBBtn>
              <MDBBtn type="submit" disabled={loading || !isDirty}>
                {loading ? (
                  <>
                    <MDBSpinner size="sm" role="status" className="me-2" />
                    Guardando...
                  </>
                ) : isSaved ? (
                  <>
                    <MDBIcon fas icon="check" className="me-2" />
                    Guardado
                  </>
                ) : (
                  <>Guardar</>
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBValidation>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default React.memo(EditFormTemplateModal);
