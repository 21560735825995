import { signOut, updatePassword } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSelect,
  MDBSpinner,
  MDBValidation,
  MDBValidationItem,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import countries from '@/assets/countries.json'; // Assume this is a list of countries
import { auth, functions } from '@/core/firebase';
import useFormValidations from '@/core/hooks/useFormValidations';
import { userState } from '@/core/hooks/useUser';
import createRefHandler from '@/core/utils/createRefHandler';
import { User } from '@/features/auth/data/models/User';
import { InputMask } from '@/features/common/components/InputMask';
import i18next from 'i18next';
import { SelectData } from 'mdb-react-ui-kit/dist/types/pro/forms/Select/types';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

/**
 * Represents the structure of the form data for editing a user's profile.
 *
 * @property {string} name - The user's name.
 * @property {string} displayName - The user's display name.
 * @property {string} phone - The user's phone number.
 * @property {string} birthday - The user's birthday.
 * @property {string} password - The user's password.
 * @property {string} passwordConfirm - The user's password confirmation.
 * @property {string} country - The user's country.
 */
type FormData = {
  name: string;
  displayName: string;
  phone: string;
  birthday: string;
  password?: string;
  passwordConfirm?: string;
  country: string;
};

/**
 * Component for user onboarding, allowing users to enter their name and phone.
 * @returns {React.ReactElement} The OnboardingScreen component.
 */
const UserInformationScreen: React.FC = (): React.ReactElement => {
  // Firebase User
  const [firebaseUser] = useAuthState(auth);

  // Translation hook
  const { t } = useTranslation();

  // Navigation hook for redirecting users
  const navigate = useNavigate();

  // User hook for state management
  const [user, setUser] = useRecoilState(userState);

  // Form validation rules hook
  const {
    nameValidation,
    usernameValidation,
    phoneValidation,
    passwordValidation,
    passwordConfirmationValidation,
    birthdayValidation,
    notEmptyValidation,
  } = useFormValidations();

  // State management
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | null>(null);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
    reset,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: user?.name || '',
      displayName: user?.displayName || '',
      phone: user?.phone || '',
      birthday: user?.birthday || '',
      password: '',
      passwordConfirm: '',
      country: user?.country || '',
    },
  });

  // Registering input fields with validation rules
  const nameRef = register('name', nameValidation()).ref;
  const displayNameRef = register('displayName', usernameValidation()).ref;
  const phoneRef = register('phone', phoneValidation(true)).ref;
  const birthdayRef = register('birthday', birthdayValidation(true)).ref;
  const passwordRef = register('password', passwordValidation()).ref;
  const passwordConfirmRef = register('passwordConfirm', passwordConfirmationValidation(getValues)).ref;

  // Update form fields when user data changes
  useEffect(() => {
    setValue('name', user?.name || '');
    setValue('displayName', user?.displayName || '');
    setValue('phone', user?.phone || '');
    setValue('birthday', user?.birthday || '');
    setValue('country', user?.country || '');
    setValue('phone', user?.phone || '');
  }, [user, setValue]);

  // Get country from https://ipapi.co/json/
  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then((response) => response.json())
      .then((data) => {
        setValue('country', data.country);
        if (user) {
          setUser({ ...user, country: data.country });
        }
      })
      .catch((_) => {
        setValue('country', 'US');
        if (user) {
          setUser({ ...user, country: 'US' });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);

    // Update password before updating user information
    try {
      await updatePassword(firebaseUser!, data.password!);
      delete data.password;
      delete data.passwordConfirm;
    } catch (error: any) {
      console.error('Error during user information update:', error);
      setGeneralError(t('onboarding.userInformation.errorUpdatingPassword'));
      await new Promise((resolve) => setTimeout(resolve, 5000));
      signOut(auth);
    }

    // Firebase function for updating user information
    const setUser = httpsCallable(functions, 'setUser');
    const setUserProfileComplete = httpsCallable(functions, 'setUserProfileComplete');

    // Update user information
    try {
      await setUser({ ...user, ...data });
      await setUserProfileComplete();
      setUser({ ...user, ...data, profileComplete: true } as User);
      setIsSaved(true);
      window.location.reload();
    } catch (error: any) {
      console.error('Error during user information update:', error);
      setGeneralError(t('onboarding.userInformation.errorSavingInformation'));
    } finally {
      setLoading(false);
    }
  };

  // Reset save status when form is dirty
  useEffect(() => {
    if (isDirty) setIsSaved(false);
  }, [isDirty]);

  useEffect(() => {
    if (user?.profileComplete) {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <MDBContainer className="py-5">
      <section>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="12" md="8" lg="6" xl="6">
            <MDBCard>
              <MDBCardBody className="p-4">
                <h2 className="fw-bold mb-2 text-uppercase text-center">{t('onboarding.userInformation.title')}</h2>
                <p className="mb-5 text-center">{t('onboarding.userInformation.description')}</p>
                <MDBValidation onSubmit={handleSubmit(onSubmit)}>
                  {/* Name Input Field */}
                  <MDBValidationItem
                    feedback={errors.name?.message || ' '}
                    invalid={errors.name ? true : false}
                    className="mb-3"
                  >
                    <MDBInput
                      label={t('onboarding.userInformation.name')}
                      {...register('name', nameValidation())}
                      defaultValue={user?.name}
                      type="text"
                      ref={createRefHandler(nameRef, errors.name)}
                      aria-describedby="nameDescription"
                    />
                    <div id="nameDescription" className={errors.name ? 'form-text mt-4' : 'form-text'}>
                      {t('onboarding.userInformation.nameDescription')}
                    </div>
                  </MDBValidationItem>

                  {/* Display Name Input Field */}
                  <MDBValidationItem
                    feedback={errors.displayName?.message || ' '}
                    invalid={errors.displayName ? true : false}
                    className="mb-3"
                  >
                    <MDBInput
                      label={t('onboarding.userInformation.displayName')}
                      {...register('displayName', nameValidation())}
                      defaultValue={user?.displayName}
                      type="text"
                      ref={createRefHandler(displayNameRef, errors.displayName)}
                      aria-describedby="displayNameDescription"
                    />
                    <div id="displayNameDescription" className={errors.displayName ? 'form-text mt-4' : 'form-text'}>
                      {t('onboarding.userInformation.displayNameDescription')}
                    </div>
                  </MDBValidationItem>

                  {/* Email Input Field (Read-only) */}
                  <MDBValidationItem className="mb-3">
                    <MDBInput
                      label={t('onboarding.userInformation.email')}
                      defaultValue={user?.email || ''}
                      type="email"
                      disabled
                      aria-describedby="emailDescription"
                    />
                    <div id="emailDescription" className="form-text">
                      {t('onboarding.userInformation.emailDescription')}
                    </div>
                  </MDBValidationItem>

                  {/* Country Dropdown */}
                  <MDBValidationItem
                    feedback={errors.country?.message || ' '}
                    invalid={!!errors.country}
                    className="mb-3"
                  >
                    <MDBSelect
                      label={t('onboarding.userInformation.country')}
                      data={countries.map((country) => ({
                        text: country.translations[i18next.language as keyof typeof country.translations],
                        value: country.country,
                      }))}
                      validation
                      preventFirstSelection
                      validFeedback={' '}
                      invalidFeedback={errors.country?.message}
                      {...register('country', notEmptyValidation())}
                      onChange={(data) => {
                        console.log(data as SelectData);
                        setValue('country', (data as SelectData).value as string, { shouldValidate: true });
                      }}
                      value={getValues('country')}
                      searchLabel={t('onboarding.userInformation.searchCountry')}
                      noResultsText={t('onboarding.userInformation.noResultsText')}
                      search
                    />
                    <div id="countryDescription" className={!!errors.country ? 'form-text mt-4' : 'form-text'}>
                      {t('onboarding.userInformation.countryDescription')}
                    </div>
                  </MDBValidationItem>

                  {/* Phone Input Field */}
                  <MDBValidationItem feedback={errors.phone?.message || ' '} invalid={!!errors.phone} className="mb-3">
                    <InputMask
                      label={t('onboarding.userInformation.phone')}
                      {...register('phone', phoneValidation(true))}
                      inputMask={
                        countries.filter(
                          (country) => country.country === (getValues('country') === '' ? 'ES' : getValues('country'))
                        )[0].phoneMask
                      }
                      maskPlaceholder
                      charPlaceholder={
                        countries.filter(
                          (country) => country.country === (getValues('country') === '' ? 'ES' : getValues('country'))
                        )[0].phoneMask
                      }
                      type="tel"
                      clearIncomplete={false}
                      onComplete={(value) => {
                        console.log('Complete');
                        setValue('phone', value);
                      }}
                      ref={createRefHandler(phoneRef, errors.phone)}
                      aria-describedby="phoneDescription"
                    />
                    <div id="phoneDescription" className={!!errors.phone ? 'form-text mt-4' : 'form-text'}>
                      {t('onboarding.userInformation.phoneDescription')}
                    </div>
                  </MDBValidationItem>

                  {/* Birthday Input Field */}
                  <MDBValidationItem
                    feedback={errors.birthday?.message || ' '}
                    invalid={!!errors.birthday}
                    className="mb-3"
                  >
                    <InputMask
                      label={t('onboarding.userInformation.birthday')}
                      {...register('birthday', birthdayValidation(true))}
                      inputMask="##/##/####"
                      maskPlaceholder
                      clearIncomplete={false}
                      charPlaceholder="dd/mm/yyyy"
                      onComplete={(value) => {
                        setValue('birthday', value);
                      }}
                      ref={createRefHandler(birthdayRef, errors.birthday)}
                      aria-describedby="birthdayDescription"
                    />
                    <div id="birthdayDescription" className={!!errors.birthday ? 'form-text mt-4' : 'form-text'}>
                      {t('onboarding.userInformation.birthdayDescription')}
                    </div>
                  </MDBValidationItem>

                  {/* Password Input Field */}
                  <MDBValidationItem
                    feedback={errors.password?.message || ' '}
                    invalid={!!errors.password}
                    className="mb-3"
                  >
                    <MDBInput
                      label={t('onboarding.userInformation.password')}
                      {...register('password', passwordValidation())}
                      type="password"
                      ref={createRefHandler(passwordRef, errors.password)}
                      aria-describedby="passwordDescription"
                    />
                    <div id="passwordDescription" className={!!errors.password ? 'form-text mt-4' : 'form-text'}>
                      {t('onboarding.userInformation.passwordDescription')}
                    </div>
                  </MDBValidationItem>

                  {/* Password Confirm Input Field */}
                  <MDBValidationItem
                    feedback={errors.passwordConfirm?.message || ' '}
                    invalid={!!errors.passwordConfirm}
                    className="mb-3"
                  >
                    <MDBInput
                      label={t('onboarding.userInformation.passwordConfirm')}
                      {...register('passwordConfirm', passwordConfirmationValidation(getValues))}
                      type="password"
                      ref={createRefHandler(passwordConfirmRef, errors.passwordConfirm)}
                      aria-describedby="passwordConfirmDescription"
                    />
                    <div
                      id="passwordConfirmDescription"
                      className={!!errors.passwordConfirm ? 'form-text mt-4' : 'form-text'}
                    >
                      {t('onboarding.userInformation.passwordConfirmDescription')}
                    </div>
                  </MDBValidationItem>

                  {/* Submit Button with Loading Indicator */}
                  <MDBBtn
                    type="submit"
                    size="lg"
                    block
                    disabled={loading || !isDirty}
                    className="d-flex align-items-center justify-content-between"
                  >
                    {loading ? (
                      <>
                        {t('onboarding.userInformation.savingInformation')}
                        <MDBSpinner size="sm" role="status" className="ms-auto" />
                      </>
                    ) : isSaved ? (
                      <>
                        {t('onboarding.userInformation.informationSaved')}
                        <MDBIcon fas icon="check" className="ms-2" />
                      </>
                    ) : (
                      <>
                        {t('onboarding.userInformation.continue')}
                        <MDBIcon fas icon="arrow-right" className="ms-2" />
                      </>
                    )}
                  </MDBBtn>

                  {/* Display General Error Message */}
                  {generalError && (
                    <div className="text-danger mt-3">
                      <MDBIcon fas icon="exclamation-triangle" className="me-2" />
                      {generalError}
                    </div>
                  )}
                </MDBValidation>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  );
};

export default React.memo(UserInformationScreen);
