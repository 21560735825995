import { FormModel } from '@/features/form/data/model/FormModel';
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const formListState = atom<FormModel[]>({
  key: 'formListState',
  default: [],
});

const formListSelector = selector({
  key: 'formListSelector',
  get: ({ get }) => get(formListState),
});

export const useFormList = () => {
  const formList = useRecoilValue(formListSelector); // For reading the form list
  const setFormList = useSetRecoilState(formListState); // For updating the form list
  const [formListStateValue, setFormListState] = useRecoilState(formListState); // For both reading and updating

  return {
    formList,
    setFormList,
    formListState: formListStateValue,
    setFormListState,
  };
};
