import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  MDBBadge,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdb-react-ui-kit';
import React, { useCallback, useEffect, useState } from 'react';

import { adminListSelector, userListState } from '@/core/hooks/useUserList';
import CreateAdminModal from '@/features/admins/components/CreateAdminModal';
import InitialsAvatar from '@/features/common/components/Avatar';
import Container from '@/features/common/components/Container';
import LoadingSkeleton from '@/features/common/components/LoadingSkeleron';
import { User } from '@/features/common/data/interface/User';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import EditAdminModal from '../components/EditAdminModal';

/**
 * AdminsScreen component represents the list of admins in the application.
 *
 * @returns The AdminsScreen component.
 */
const AdminsScreen: React.FC = () => {
  // Admin List hook for state management
  const setUserList = useSetRecoilState(userListState);
  const adminList = useRecoilValue(adminListSelector);

  // State variables
  const [fetching, setFetching] = useState<boolean>(true);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [adminUid, setAdminUid] = useState<string | undefined>(undefined);

  /**
   * Toggles the create admin modal.
   */
  const toggleCreateModal = () => setCreateModal(!createModal);

  /**
   * Toggles the edit admin modal.
   */
  const toggleEditModal = () => {
    if (editModal) {
      setAdminUid(undefined);
    }
    return setEditModal(!editModal);
  };

  /**
   * Fetches the list of admins from Firebase and updates the state.
   * This is a memoized function that will be recreated only if one of its dependencies changes.
   */
  const getUserList = useCallback(async () => {
    try {
      // Calls 'getUserList' Cloud Function to fetch the list of admins
      const userListResult = await httpsCallable(getFunctions(), 'getUserList')();

      // Updates the state with the list of admins
      setUserList(userListResult.data as User[]);
    } catch (error) {
      console.error(error);
    } finally {
      // Sets the 'fetching' state to false once the operation is complete
      setFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Effect hook to fetch the list of admins on component mount.
   * This effect runs once after the component mounts.
   */
  useEffect(() => {
    getUserList(); // Invokes the getUserList function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        fluid
        title="Administradores"
        navBarActions={
          <MDBBtn color="primary" onClick={toggleCreateModal}>
            <MDBIcon className="me-2" icon="plus" />
            Crear
          </MDBBtn>
        }
      >
        <>
          {fetching ? (
            <LoadingSkeleton />
          ) : adminList.length > 0 ? (
            <div className="overflow-hidden">
              <MDBTable>
                <MDBTableHead light>
                  <tr>
                    <th>Nombre</th>
                    <th className="text-center">
                      <MDBIcon icon="edit" fas /> Creados
                    </th>
                    <th className="text-center">
                      <MDBIcon icon="edit" fas /> Pendientes
                    </th>
                    <th className="text-center">
                      <MDBIcon icon="edit" fas /> Completados
                    </th>
                    <th className="text-center">Estado</th>
                    <th className="text-end">Acciones</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody style={{ verticalAlign: 'middle' }}>
                  {adminList.map((admin: User) => (
                    <tr key={admin.uid}>
                      <td>
                        <div className="d-flex align-items-center">
                          <InitialsAvatar
                            name={admin.name ? admin.name ?? '' : admin.email}
                            style={{ width: '45px', height: '45px', fontSize: 18 }}
                          />
                          <div className="ms-3">
                            <p className="fw-bold mb-1">{admin.name}</p>
                            <p className="text-muted mb-0">{admin.email}</p>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">0</td>
                      <td className="text-center">0</td>
                      <td className="text-center">0</td>
                      <td className="text-center">
                        <MDBBadge
                          light
                          color={!admin.disabled ? (admin.profileComplete ? 'success' : 'warning') : 'danger'}
                          pill
                        >
                          {!admin.disabled ? (admin.profileComplete ? 'Activo' : 'Pendiente') : 'Inactivo'}
                        </MDBBadge>
                      </td>
                      <td className="text-end pe-2">
                        <MDBBtn
                          className="fw-bold"
                          color="link"
                          size="sm"
                          rippleColor="dark"
                          onClick={() => {
                            setAdminUid(admin.uid);
                            toggleEditModal();
                          }}
                        >
                          Editar
                        </MDBBtn>
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>
          ) : (
            <MDBContainer fluid className="h-100">
              <MDBRow className="h-100 justify-content-center align-items-center">
                <MDBCol className="text-center">
                  <MDBIcon className="mb-3" icon="users-cog" size="2x" />
                  <h6 className="fw-bold">No existen administradores</h6>
                  <p className="text-muted">Crea un administrador</p>
                  <MDBBtn color="primary" onClick={toggleCreateModal}>
                    <MDBIcon className="me-2" icon="plus" />
                    Crear
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          )}
        </>
      </Container>
      <CreateAdminModal open={createModal} setOpen={setCreateModal} toggle={toggleCreateModal} />
      <EditAdminModal open={editModal} setOpen={setEditModal} toggle={toggleEditModal} uid={adminUid} />
    </>
  );
};

export default React.memo(AdminsScreen);
