import { MDBBtn, MDBIcon, MDBInput, MDBSpinner, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { auth } from '@/core/firebase';
import useFormValidations from '@/core/hooks/useFormValidations';
import createRefHandler from '@/core/utils/createRefHandler';
import { signInWithEmailAndPassword } from 'firebase/auth';

/**
 * Represents the structure of the form data for signing-in a user.
 *
 * @type {Object} FormData
 * @property {string} email - The user's email.
 * @property {string} password - The user's password.
 */
type FormData = {
  email: string;
  password: string;
};

/**
 * Interface for the properties passed to the SignInForm component.
 * @interface SignInFormProps
 * @property {Function} onForgotPassword - Function to handle forgot password action.
 */
interface SignInFormProps {
  onForgotPassword: () => void;
}

/**
 * Component for the sign-in form.
 * @param props - The properties passed to the component.
 * @param props.onForgotPassword - Function to handle forgot password action.
 * @return SignInForm component.
 */
const SignInForm: React.FC<SignInFormProps> = ({ onForgotPassword }) => {
  // Translation hook
  const { t } = useTranslation();

  // Validation rules hook
  const { emailValidation, passwordValidation } = useFormValidations();

  // Setting up state management
  const [loading, setLoading] = useState<boolean>(false);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setError,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { email: '', password: '' },
  });

  // Registering input fields with validation rules
  const emailRef = register('email', emailValidation()).ref;
  const passwordRef = register('password', passwordValidation()).ref;

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
    } catch (error: any) {
      handleEmailSignInError(error, { ...data });
    } finally {
      setLoading(false);
    }
  };

  // Handle error messages for email sign-in
  const handleEmailSignInError = (error: any, data: FormData) => {
    const errorMappings: any = {
      'auth/user-not-found': { field: 'email', message: t('auth.signIn.userNotFound') },
      'auth/invalid-email': { field: 'email', message: t('auth.signIn.invalidEmail') },
      'auth/user-disabled': { field: 'email', message: t('auth.signIn.userDisabled') },
      'auth/invalid-password': { field: 'password', message: t('auth.signIn.invalidPassword') },
      'auth/invalid-credential': { field: 'password', message: t('auth.signIn.invalidCredential') },
      'auth/internal-error': { field: 'password', message: t('auth.signIn.internalError') },
    };

    const defaultError = { field: 'password', message: t('auth.signIn.internalError') };
    const { field, message } = errorMappings[error.code] || defaultError;

    console.error(error);
    reset({ ...data });
    setError(field, { type: 'custom', message });
  };

  return (
    <MDBValidation onSubmit={handleSubmit(onSubmit)}>
      {/* Email Input Field */}
      <MDBValidationItem feedback={errors.email?.message || ' '} invalid={!!errors.email} className="mb-4">
        <MDBInput
          label={t('auth.signIn.email')}
          {...register('email', emailValidation())}
          type="email"
          ref={createRefHandler(emailRef, errors.email)}
        />
      </MDBValidationItem>

      {/* Password Input Field */}
      <MDBValidationItem
        className={!!errors.email ? 'pt-4' : ''}
        feedback={errors.password?.message || ' '}
        invalid={!!errors.password}
      >
        <MDBInput
          label={t('auth.signIn.password')}
          {...register('password', passwordValidation())}
          type="password"
          ref={createRefHandler(passwordRef, errors.password)}
        />
      </MDBValidationItem>

      {/* Forgot Password Button */}
      <MDBBtn
        type="button"
        color="link"
        noRipple
        size="lg"
        className="my-3 p-0 text-transform-none fs-6 float-end"
        onClick={onForgotPassword}
      >
        {t('auth.signIn.forgotPassword')}
      </MDBBtn>

      {/* Submit Button with Loading Indicator */}
      <MDBBtn
        type="submit"
        block
        size="lg"
        disabled={loading || !isDirty}
        className="d-flex align-items-center justify-content-between mt-0 mb-4"
      >
        {loading ? (
          <>
            {t('auth.signIn.signingIn')}
            <MDBSpinner size="sm" role="status" className="ms-auto" />
          </>
        ) : (
          <>
            {t('auth.signIn.signIn')}
            <MDBIcon fas icon="arrow-right" className="ms-auto" />
          </>
        )}
      </MDBBtn>
    </MDBValidation>
  );
};

export default SignInForm;
