import { FormSectionModel } from '@/features/templates/data/model/FormSectionModel';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdb-react-ui-kit';
import FormField from './FormField';

type FormSectionProps = {
  section: FormSectionModel;
};

const FormSection: React.FC<FormSectionProps> = ({ section }) => {
  return (
    <>
      <MDBCard className="border">
        <MDBCardHeader className="border-0 pb-0">
          <h5 className="mb-0">{section.name}</h5>
        </MDBCardHeader>
        <MDBCardBody className='py-3'>
          {section.fields.map((field) => (
            <FormField
              key={field.id}
              field={field}
            />
          ))}
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

export default FormSection;
