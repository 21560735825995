import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';

import SignUpForm from '@/features/auth/components/SignUpForm';
import SocialAuthButtons from '@/features/auth/components/SocialAuthButtons';
import { useNavigate } from 'react-router-dom';
import SignUpCard from '../components/SignUpCard';

/**
 * Component for handling sign-up functionality.
 * Allows users to sign-up with their email and password, or with Google.
 * @return SignUpScreen component.
 */
const SignUpScreen: React.FC = () => {
  // Navigation hook for redirecting users
  const navigate = useNavigate();

  // Function to navigate back to the sign-in screen
  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <MDBContainer className="d-flex align-items-center justify-content-center py-5" style={{ minHeight: '100vh' }}>
      <section className="w-100">
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="12" md="8" lg="6" xl="6">
            <SignUpCard>
              <SignUpForm onSignUp={navigateToHome} />
              <SocialAuthButtons isSignUp={true} />
            </SignUpCard>
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  );
};

export default SignUpScreen;
