import { formState } from '@/core/hooks/useForm';
import useFormValidations from '@/core/hooks/useFormValidations';
import createRefHandler from '@/core/utils/createRefHandler';
import { FormFieldModel } from '@/features/templates/data/model/FormFieldModel';
import { MDBDatepicker, MDBInput, MDBSelect, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';
import { SelectData } from 'mdb-react-ui-kit/dist/types/pro/forms/Select/types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';

type FormFieldProps = {
  field: FormFieldModel;
};

type FormData = {
  [key: string]: string;
};

const FormField: React.FC<FormFieldProps> = ({ field }) => {
  // Form validation rules hook
  const { numberValidation, emailValidation, phoneValidation } = useFormValidations();

  // Recoil state
  const [form, setForm] = useRecoilState(formState);

  // useForm hook initialization with form validation rules
  const {
    register,
    formState: { errors, isSubmitted },
    setValue,
    getValues,
  } = useFormContext<FormData>();

  // Form change handler
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    setForm((prevForm) => ({
      ...prevForm!,
      formData: {
        ...prevForm!.formData,
        [name]: value,
      },
    }));
  };

  const fieldRef = register(field.id, {
    required: field.isRequired ? 'El campo es requerido' : undefined,
    onChange: handleChange,
  }).ref;

  useEffect(() => {
    if (field.id && form?.formData && form?.formData[field.id]) {
      setValue(field.id, form?.formData[field.id] ?? '', {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.id, setValue]);

  return (
    <>
      <MDBValidation isValidated={isSubmitted} noValidate>
        <MDBValidationItem
          feedback={errors[field.id as keyof FormData]?.message || ' '}
          invalid={!!errors[field.id as keyof FormData]}
          className="mb-3"
        >
          {field.type === 'text' && (
            <MDBInput
              label="Su respuesta"
              {...register(field.id, {
                required: field.isRequired ? 'El campo es requerido' : undefined,
                onChange: handleChange,
              })}
              type="text"
              required={field.isRequired}
              ref={createRefHandler(fieldRef, errors[field.id as keyof FormData])}
            />
          )}
          {field.type === 'number' && (
            <MDBInput
              label="Su respuesta"
              {...register(field.id, { ...numberValidation(field.isRequired), onChange: handleChange })}
              type="number"
              required={field.isRequired}
              ref={createRefHandler(fieldRef, errors[field.id as keyof FormData])}
            />
          )}
          {field.type === 'date' && (
            <MDBDatepicker
              label="Su respuesta"
              {...register(field.id, {
                required: field.isRequired ? 'El campo es requerido' : undefined,
                onChange: handleChange,
              })}
              type="date"
              required={field.isRequired}
              ref={createRefHandler(fieldRef, errors[field.id as keyof FormData])}
              inputToggle
            />
          )}
          {field.type === 'email' && (
            <MDBInput
              label="Su respuesta"
              {...register(field.id, { ...emailValidation(field.isRequired), onChange: handleChange })}
              type="email"
              required={field.isRequired}
              ref={createRefHandler(fieldRef, errors[field.id as keyof FormData])}
            />
          )}
          {field.type === 'phone' && (
            <MDBInput
              label="Su respuesta"
              {...register(field.id, { ...phoneValidation(field.isRequired), onChange: handleChange })}
              type="phone"
              required={field.isRequired}
              ref={createRefHandler(fieldRef, errors[field.id as keyof FormData])}
            />
          )}
          {field.type === 'photoUpload' && (
            <MDBInput label={field.label} type="file" required={field.isRequired} onChange={handleChange} />
          )}
          {field.type === 'select' && (
            <MDBSelect
              label="Escoger una opción"
              {...register(field.id, {
                required: field.isRequired ? 'El campo es requerido' : undefined,
              })}
              onChange={(e) => {
                // Check if the value actually needs updating to prevent unnecessary state changes
                const currentValue = getValues(field.id);
                if ((e as SelectData).value?.toString() !== currentValue) {
                  // Manually handle the change event to conform to react-hook-form
                  const event = {
                    target: {
                      name: field.id,
                      value: (e as SelectData).value?.toString() ?? '',
                    },
                  } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>;
                  handleChange(event);
                  setValue(field.id, (e as SelectData).value?.toString() ?? '', {
                    shouldValidate: false,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }
              }}
              aria-describedby="templateId"
              data={field.options?.split(', ')?.map((option) => ({ text: option, value: option })) ?? []}
              value={getValues(field.id)}
            />
          )}
          <div id={field.id} className={!!errors[field.id as keyof FormData] ? 'form-text pt-3' : 'form-text'}>
            {field.label} {field.isRequired && <span className="text-danger">*</span>}
          </div>
        </MDBValidationItem>
      </MDBValidation>
    </>
  );
};

export default FormField;
