import { auth } from '@/core/firebase';
import { sendEmailVerification, signOut } from 'firebase/auth';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBSpinner,
} from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Component for handling email verification functionality.
 * Allows users to verify their email address and resend the verification email if needed.
 * @return VerifyEmailScreen component.
 */
const VerifyEmailScreen: React.FC = () => {
  // Translation hook
  const { t } = useTranslation();

  // Loading state
  const [loading, setLoading] = useState(false);

  // Message state
  const [message, setMessage] = useState<string | null>(null);

  // Function to resend verification email
  const handleResendVerification = async () => {
    setLoading(true);
    try {
      if (auth.currentUser) {
        await sendEmailVerification(auth.currentUser);
        setMessage(t('auth.emailVerification.emailSent'));
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      setMessage(t('auth.emailVerification.emailSendError'));
    } finally {
      setLoading(false);
    }
  };

  // Function to logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <MDBContainer className="d-flex align-items-center justify-content-center py-5" style={{ minHeight: '100vh' }}>
      <section className="w-100">
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="12" md="8" lg="6" xl="6">
            <MDBCard>
              <MDBCardTitle
                tag="div"
                className="border-bottom d-flex justify-content-between align-items-center p-4 m-0"
              >
                <h2 className="fw-bold m-0">{t('auth.emailVerification.title')}</h2>
                <MDBBtn tag="a" noRipple onClick={handleLogout} color="link" className="p-0 d-flex align-items-center">
                  <MDBIcon fas icon="sign-out-alt" className="me-2" />
                  {t('auth.emailVerification.logout')}
                </MDBBtn>
              </MDBCardTitle>
              <MDBCardBody className="p-4">
                <p className="mb-4">{t('auth.emailVerification.message')}</p>
                <MDBBtn
                  onClick={handleResendVerification}
                  block
                  size="lg"
                  disabled={loading}
                  className="d-flex align-items-center justify-content-between mt-4 mb-3"
                >
                  {loading ? (
                    <>
                      {t('auth.emailVerification.sending')}
                      <MDBSpinner size="sm" role="status" className="ms-auto" />
                    </>
                  ) : (
                    <>
                      {t('auth.emailVerification.resend')}
                      <MDBIcon fas icon="paper-plane" className="ms-auto" />
                    </>
                  )}
                </MDBBtn>
                {message && (
                  <p className="valid-feedback d-block position-relative mb-0 mt-3">
                    <MDBIcon fas icon="check-circle" className="me-2" />
                    {message}
                  </p>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  );
};

export default VerifyEmailScreen;
