import { FormModel } from '@/features/form/data/model/FormModel';
import { Timestamp } from 'firebase/firestore';
import { atom } from 'recoil';

// Define the initial state for your form
const initialFormState: FormModel = {
  id: '',
  ownerUid: '',
  code: 0,
  templateId: '',
  status: 'created',
  createdAt: Timestamp.now(),
  sentAt: Timestamp.now(),
  inProgressAt: Timestamp.now(),
  completedAt: Timestamp.now(),
  fullName: '',
  email: '',
  token: '',
  formData: {},
};

const formState = atom<FormModel | null>({
  key: 'formState',
  default: null,
});

export { formState, initialFormState };

