import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getFunctions, httpsCallable } from 'firebase/functions';

const useFormValidations = () => {
  const { t } = useTranslation();
  const functions = getFunctions();

  const emailValidation = (isRequired = true) => ({
    required: isRequired && t('validations.emailRequired'),
    pattern: {
      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
      message: t('validations.emailInvalid'),
    },
  });

  const nameValidation = (isRequired = true) => ({
    required: isRequired && t('validations.nameRequired'),
    pattern: {
      value: /^[a-zA-Z\s]+$/,
      message: t('validations.nameInvalid'),
    },
  });

  const usernameValidation = (isRequired = true) => ({
    required: isRequired && t('validations.usernameRequired'),
    pattern: {
      value: /^[a-zA-Z0-9_-]+$/,
      message: t('validations.usernameInvalid'),
    },
    minLength: {
      value: 3,
      message: t('validations.usernameMinLength'),
    },
    maxLength: {
      value: 20,
      message: t('validations.usernameMaxLength'),
    },
  });

  const phoneValidation = (isRequired = false) => ({
    required: isRequired && t('validations.phoneRequired'),
    pattern: {
      value: /^(\+\d{1,3}[-\s.]?)?\(?(?:\d{1,3})?\)?[-\s.]?\d{1,4}[-\s.]?\d{1,4}[-\s.]?\d{1,4}$/,
      message: t('validations.phoneInvalid'),
    },
  });

  const passwordValidation = (isRequired = true) => ({
    required: isRequired && t('validations.passwordRequired'),
    minLength: {
      value: 6,
      message: t('validations.passwordMinLength'),
    },
  });

  const passwordConfirmationValidation = <T extends FieldValues>(getValues: UseFormGetValues<T>) => ({
    required: t('validations.passwordConfirmRequired'),
    validate: (value: string | undefined) => value === getValues().password || t('validations.passwordConfirmMatch'),
  });

  const notEmptyValidation = (isRequired = true) => ({
    required: isRequired && t('validations.requiredField'),
  });

  const numberValidation = (isRequired = true) => ({
    required: isRequired && t('validations.numberRequired'),
    pattern: {
      value: /^\d+(\.\d+)?$/,
      message: t('validations.numberInvalid'),
    },
  });

  const birthdayValidation = (isRequired = true) => ({
    required: isRequired && t('validations.birthday.required'),
    validate: (value: string) => {
      const [day, month, year] = value.split('/');
      const date = new Date(+year, +month - 1, +day);
      const today = new Date();
      const minDate = new Date();
      minDate.setFullYear(today.getFullYear() - 120); // Assuming max age is 120 years
      if (isNaN(date.getTime())) {
        console.log(t('validations.birthday.invalid'));
        return t('validations.birthday.invalid');
      }
      if (date > today) {
        console.log(t('validations.birthday.future'));
        return t('validations.birthday.future');
      }
      if (date < minDate) {
        console.log(t('validations.birthday.tooOld'));
        return t('validations.birthday.tooOld');
      }
    }
  });

  const referralCodeValidation = (isRequired = false) => ({
    required: isRequired && t('validations.referralCodeRequired'),
    validate: async (value: string) => {
      if (!value && !isRequired) return true;
      if (value.length !== 5) return t('validations.referralCodeInvalid');
      
      const referralCodeRegex = /^[A-Z0-9]{5}$/;
      if (!referralCodeRegex.test(value)) return t('validations.referralCodeInvalid');

      try {
        const validateReferralCode = httpsCallable(functions, 'checkReferralCode');
        const result = await validateReferralCode({ referralCode: value });
        const { isValid } = result.data as { isValid: boolean };
        
        return isValid || t('validations.referralCodeInvalid');
      } catch (error) {
        console.error('Error validating referral code:', error);
        return t('validations.referralCodeInvalid');
      }
    },
  });

  return {
    emailValidation,
    nameValidation,
    usernameValidation,
    phoneValidation,
    passwordValidation,
    passwordConfirmationValidation,
    notEmptyValidation,
    numberValidation,
    birthdayValidation,
    referralCodeValidation,
  };
};

export default useFormValidations;
