import { appleProvider, auth, googleProvider } from '@/core/firebase';
import { signInWithPopup } from 'firebase/auth';
import { MDBBtn, MDBIcon, MDBSpinner } from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Interface for the SocialAuthButtons component.
 * @interface SocialAuthButtonsProps
 * @property {boolean} isSignUp - Whether the component is used for sign-up.
 */
interface SocialAuthButtonsProps {
  isSignUp?: boolean;
}

/**
 * Component for social authentication buttons.
 * @param props - The properties passed to the component.
 * @param props.isSignUp - Whether the component is used for sign-up.
 * @return SocialAuthButtons component.
 */
const SocialAuthButtons: React.FC<SocialAuthButtonsProps> = ({ isSignUp = false }) => {
  // Translation hook
  const { t } = useTranslation();

  // Loading states for Google and Apple authentication
  const [loadingGoogle, setLoadingGoogle] = useState<boolean>(false);
  const [loadingApple, setLoadingApple] = useState<boolean>(false);

  // Error states for Google and Apple authentication
  const [googleAuthenticationError, setGoogleAuthenticationError] = useState<string | null>(null);
  const [appleAuthenticationError, setAppleAuthenticationError] = useState<string | null>(null);

  // Handle Google sign-in authentication
  const handleGoogleAuthentication = async () => {
    setLoadingGoogle(true);
    try {
      await signInWithPopup(auth, googleProvider);
      setGoogleAuthenticationError(null);
    } catch (error: any) {
      handleGoogleSignInError(error);
    } finally {
      setLoadingGoogle(false);
    }
  };

  // Handle Apple sign-in authentication
  const handleAppleAuthentication = async () => {
    setLoadingApple(true);
    try {
      await signInWithPopup(auth, appleProvider);
      setAppleAuthenticationError(null);
    } catch (error: any) {
      handleAppleSignInError(error);
    } finally {
      setLoadingApple(false);
    }
  };

  // Handle error messages for Google sign-in
  const handleGoogleSignInError = (error: any) => {
    const errorMappings: any = {
      'auth/popup-closed-by-user': 'Se ha cancelado el inicio de sesión',
      'auth/popup-blocked': 'Se ha bloqueado el inicio de sesión por el navegador',
      'auth/cancelled-popup-request': 'Se ha cancelado el inicio de sesión',
      'auth/user-disabled': 'El usuario ha sido deshabilitado',
      'auth/user-not-found': 'El usuario no existe',
    };
    const defaultError = 'Ha ocurrido un error';
    const errorMessage = errorMappings[error.code] || defaultError;

    console.error(error.code);
    setGoogleAuthenticationError(errorMessage);
  };

  // Handle error messages for Apple sign-in
  const handleAppleSignInError = (error: any) => {
    const errorMappings: any = {
      'auth/popup-closed-by-user': 'Se ha cancelado el inicio de sesión',
      'auth/popup-blocked': 'Se ha bloqueado el inicio de sesión por el navegador',
      'auth/cancelled-popup-request': 'Se ha cancelado el inicio de sesión',
      'auth/user-disabled': 'El usuario ha sido deshabilitado',
      'auth/user-not-found': 'El usuario no existe',
    };
    const defaultError = 'Ha ocurrido un error';
    const errorMessage = errorMappings[error.code] || defaultError;

    console.error(error.code);
    setAppleAuthenticationError(errorMessage);
  };

  const orText = isSignUp ? t('auth.signUp.or') : t('auth.signIn.or');

  return (
    <>
      <div className="d-flex align-items-center justify-content-center text-center mb-4">
        <div className="flex-grow-1 border-bottom my-0 me-3" />
        <p className="mb-0 mx-2">{orText}</p>
        <div className="flex-grow-1 border-bottom my-0 ms-3" />
      </div>
      <div>
        {/* Google and Apple authentication buttons */}
        <div className="d-flex justify-content-between">
          <MDBBtn
            color="primary"
            type="button"
            size="lg"
            style={{ background: '#EA4335', width: '48%' }}
            onClick={() => handleGoogleAuthentication()}
            disabled={loadingGoogle}
            className="d-flex align-items-center justify-content-between"
          >
            {loadingGoogle ? (
              <>
                {t('auth.signIn.signingIn')}
                <MDBSpinner size="sm" role="status" className="ms-auto" />
              </>
            ) : (
              <>
                {t('auth.signIn.google')}
                <MDBIcon fab icon="google" className="ms-auto" />
              </>
            )}
          </MDBBtn>

          <MDBBtn
            color="dark"
            type="button"
            size="lg"
            style={{ width: '48%' }}
            onClick={() => handleAppleAuthentication()}
            disabled={loadingApple}
            className="d-flex align-items-center justify-content-between"
          >
            {loadingApple ? (
              <>
                {t('auth.signIn.signingIn')}
                <MDBSpinner size="sm" role="status" className="ms-auto" />
              </>
            ) : (
              <>
                {t('auth.signIn.apple')}
                <MDBIcon fab icon="apple" className="ms-auto" />
              </>
            )}
          </MDBBtn>
        </div>

        {/* Google authentication error message */}
        {googleAuthenticationError !== null && (
          <p className="invalid-feedback d-block position-relative mb-0 mt-3">{googleAuthenticationError}</p>
        )}

        {/* Apple authentication error message */}
        {appleAuthenticationError !== null && (
          <p className="invalid-feedback d-block position-relative mb-0 mt-3">{appleAuthenticationError}</p>
        )}
      </div>
    </>
  );
};

export default SocialAuthButtons;
